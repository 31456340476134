(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@smartdesign/element-base"));
	else if(typeof define === 'function' && define.amd)
		define(["@smartdesign/element-base"], factory);
	else if(typeof exports === 'object')
		exports["@smartdesign/button"] = factory(require("@smartdesign/element-base"));
	else
		root["@smartdesign/button"] = factory(root["@smartdesign/element-base"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__917__) => {
return 