import { LitElement, TemplateResult, html, unsafeCSS, PropertyValues, css } from "lit";
import { property } from "lit/decorators/property.js";
import ListUtil, { ScrollToAlignment } from "./list-util";
import ListItem, { generator } from "@smartdesign/list-item";
export { ListDataProvider } from "./data-provider";

const ResizeObserver = require("resize-observer-polyfill").default as {
    new (callback: ResizeObserverCallback): ResizeObserver;
};
const style = require("./style.scss");

export type ItemGenerator = (data: unknown, index: number) => HTMLElement;
export enum SelectionType {
    TriggerOnly = "trigger-only",
    Single = "single",
    Multi = "multi",
}

interface ItemRenderData {
    index: number;
    top: number;
    physicalIndex: number;
    dataHash: string;
    data?: unknown;
}

let idCounter = 0;

export default class VirtualList extends LitElement {
    public static readonly ID: string = "sd-virtual-list";
    public static ensureDefined = (): void => {
        ListItem.ensureDefined();
        if (!customElements.get(VirtualList.ID)) {
            customElements.define(VirtualList.ID, VirtualList);
        }
    };

    @property({ type: Number, attribute: "item-height", reflect: true })
    public itemHeight: number;
    @property({ type: Number })
    public itemCount: number;
    @property({ type: Array, attribute: false })
    public items: unknown[] = [];
    @property({ type: String, attribute: "selection-type", reflect: true, noAccessor: true })
    public selectionType: SelectionType = SelectionType.TriggerOnly;
    @property({ type: String, attribute: true, reflect: true })
    public id: string = VirtualList.ID + "_" + idCounter++;
    // aria attributes
    @property({ type: String, reflect: true })
    public role = "listbox";

    public itemGenerator: ItemGenerator = generator;
    public finalSizeIsKnown: boolean;

    private _lastKnownScrollTop = 0;
    private _lastRenderedScrollTop = 0;

    private _itemsRenderData: ItemRenderData[] = [];
    private _elementCache: Map<string, HTMLElement> = new Map();

    private _firstVisibleIndex: number;
    private _lastVisibleIndex: number;
    private _visibleItemsNum = 0;

    private _selectedIndices: number[] = [];
    private _focusIndex = -1;
    private _focusVisibleIndex = -1;
    private _resizeObserver: ResizeObserver;
    private _lastKnownHeight = 0;
    private _increaseWidthOnNextRenderIfNeeded: boolean;
    private _reachedMaxWidth: boolean;

    @property({ type: Number, attribute: "focus-index", reflect: true })
    public get focusIndex(): number {
        return this._focusIndex;
    }

    public set focusIndex(index: number) {
        if (index >= -1 && index < this.itemCount) {
            const oldValue = this._focusIndex;
            this._focusIndex = index;
            if (index <= this._firstVisibleIndex || this._lastVisibleIndex <= index) {
                this.scrollToItem(index);
            }
            if (oldValue != index) {
                if (index == -1) {
                    this.removeAttribute("aria-activedescendant");
                }
                this.requestUpdate("focusIndex", oldValue);
            }
        }
    }

    @property({ type: Array, attribute: false })
    public get selectedIndices(): number[] {
        return this._selectedIndices;
    }

    public set selectedIndices(selectedIndices: number[]) {
        if (selectedIndices) {
            // Parse to primitive numbers as the virtual-list uses numbers and not objects for the indexOf(item-index) checks.
            this._selectedIndices = selectedIndices.map((index) => Number(index));
        } else {
            this._selectedIndices = [];
        }
        this.requestUpdate("selectedIndices");
    }

    public scrollToItem(index: number, alignment: ScrollToAlignment = "auto"): void {
        this.scrollTop = ListUtil.getOffsetForIndexAndAlignment(
            this.normalizeIndex(index),
            alignment,
            this.scrollTop,
            this.itemHeight,
            this.height,
            this.itemCount
        );
        // The render might have already been scheduled, but the onScroll event is dispatched later.
        // We need to update the _lastKnownScrollTop manually to ensure an up-to-date value is used even for the next render.
        this._lastKnownScrollTop = this.scrollTop;
    }

    public getListItem(index: number): HTMLElement {
        if (!this.shadowRoot || index < this._firstVisibleIndex || this._lastVisibleIndex < index) {
            return null;
        }
        return this.querySelector(`[item-index="${index}"]`);
    }

    constructor() {
        super();
        this._resizeObserver = new ResizeObserver(() => {
            if (this._lastKnownHeight !== this.offsetHeight) {
                this._lastKnownHeight = this.offsetHeight;
                this.requestUpdate();
            }
        });
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this._resizeObserver.observe(this);
        // these are needed because when reattaching the list to the DOM
        // then the scroll position is reset but no scroll event is called
        // so the list shows the items at incorrect position
        if (this.scrollTop !== this._lastKnownScrollTop) {
            this.scrollTop = this._lastKnownScrollTop;
            this.requestUpdate();
        }
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback();
        this._resizeObserver.disconnect();
    }

    public firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        this.addEventListener("scroll", this.onScroll);
        this.addEventListener("keydown", this.handleKeyDown);
        this.addEventListener("click", this.handleClick);
        this.addEventListener("focus", () => {
            if (this.matches(":focus-visible")) {
                if (this.focusIndex == -1) {
                    if (this.selectedIndices) {
                        this.focusIndex = this.selectedIndices[0];
                    }
                    if (this.focusIndex == -1 && this.itemCount > 0) {
                        this.focusIndex = 0;
                    }
                }
                if (this._focusVisibleIndex == -1) {
                    this._focusVisibleIndex = this.focusIndex;
                    this.requestUpdate();
                }
            }
        });
        this.addEventListener("blur", () => {
            if (this._focusVisibleIndex != -1) {
                this._focusVisibleIndex = -1;
                this.requestUpdate();
            }
        });
        if (this.selectedIndices.length > 0) {
            this.scrollToItem(this.selectedIndices[0], "center");
        }
    }

    static get styles() {
        return [
            css`
                ${unsafeCSS(style)}
            `,
        ];
    }
    public render(): TemplateResult {
        this.updateItemsRenderData();
        return html`
            <div class="container" style="height: ${this.itemCount * this.itemHeight}px">
                <slot name="items"></slot>
            </div>
        `;
    }

    public updated(_changedProperties: PropertyValues): void {
        super.updated(_changedProperties);
        this._lastRenderedScrollTop = this._lastKnownScrollTop;
        this.updateItems();

        if (
            (this._increaseWidthOnNextRenderIfNeeded || this._reachedMaxWidth) && //
            this._firstVisibleIndex < this._lastVisibleIndex
        ) {
            if (!this.querySelector("[item-index]")) {
                // If ShadyDOM is in use, then it needs a delay, because dom mutations are not applied immediately.
                const observer = new MutationObserver(() => {
                    this.adjustWidthIfNeeded();
                    observer.disconnect();
                });
                observer.observe(this);
            } else {
                this.adjustWidthIfNeeded();
            }
        }
    }

    private adjustWidthIfNeeded() {
        if (this._increaseWidthOnNextRenderIfNeeded) {
            this._increaseWidthOnNextRenderIfNeeded = false;
            window.requestAnimationFrame(() => {
                const remainingWidth = Number.parseInt(getComputedStyle(this).maxWidth) - this.offsetWidth;
                if (remainingWidth == 0) {
                    this._reachedMaxWidth = true;
                    this.enableLineClampOnItemsIfNeeded();
                } else {
                    this._reachedMaxWidth = false;
                    const missingWidths = [...this.querySelectorAll("[item-index]")].map((item) => {
                        if (item instanceof ListItem) {
                            item.enableLineClamp = false;
                            const missingWidthForTexts = item.missingWidthForTexts;
                            if (missingWidthForTexts > remainingWidth) {
                                item.enableLineClamp = true;
                            }
                            return missingWidthForTexts;
                        }
                    });
                    const additionalWidth = Math.max(...missingWidths);
                    if (additionalWidth > 0) {
                        this.style.width = `${this.offsetWidth + additionalWidth}px`;
                    }
                }
            });
        } else if (this._reachedMaxWidth) {
            this.enableLineClampOnItemsIfNeeded();
        }
    }

    private enableLineClampOnItemsIfNeeded() {
        this.querySelectorAll("[item-index]").forEach((item) => {
            if (item instanceof ListItem) {
                item.enableLineClamp = item.enableLineClamp || item.missingWidthForTexts > 0;
            }
        });
    }

    /**
     * Searches for list-items where there is a need for an additional width (ellipsis maybe shown) and increases the width of the list,
     * therefore all the content is visible without tooltips. As it can be an expensive task to retrieve the required details, calling
     * this function has an effect only on the very next render. Note that it only works if the virtual-list works with sd-list-item elements.
     * If the maximum width is reached, line clamp is enabled on list items as a last resort approach to show the content if possible.
     */
    public increaseWidthOnNextRenderIfNeeded(): void {
        this._increaseWidthOnNextRenderIfNeeded = true;
    }

    private updateItems() {
        const unusedItems: Element[] = [...this.querySelectorAll("[item-index]")];
        const renderedItems: Map<string, HTMLElement> = new Map();

        const newItemsFragment = document.createDocumentFragment();
        for (const renderData of this._itemsRenderData) {
            const itemElement = this.renderItem(renderData);
            if (!itemElement.parentElement) {
                newItemsFragment.appendChild(itemElement);
            }
            renderedItems.set(renderData.dataHash, itemElement);
            const index = unusedItems.indexOf(itemElement);
            if (index !== -1) {
                unusedItems.splice(index, 1);
            }
        }
        this.appendChild(newItemsFragment);

        for (const unusedItemElement of unusedItems) {
            if (unusedItemElement instanceof ListItem) {
                unusedItemElement.enableLineClamp = false;
            }
            this.removeChild(unusedItemElement);
        }

        renderedItems.forEach((itemElement, dataHash) => {
            this._elementCache.set(dataHash, itemElement);
        });
    }

    private renderItem({ index, top, dataHash, data }: ItemRenderData): HTMLElement {
        let element: HTMLElement;
        if (data) {
            if (this._elementCache.has(dataHash)) {
                element = this._elementCache.get(dataHash);
                this._elementCache.delete(dataHash); // Allow to be rendered twice
            } else {
                element = this.itemGenerator(data, index);
                element.setAttribute("slot", "items");
                // Do not add to cache yet, because the same item might need to be rendered twice
            }
        } else {
            element = document.createElement("div");
            element.setAttribute("placeholder-item", "");
            element.setAttribute("slot", "items");
        }
        Object.assign(element.style, {
            transform: `translateY(${top}px)`,
            height: `${this.itemHeight}px`,
        });
        element.setAttribute("item-index", index.toString());
        element.setAttribute("aria-setsize", String(this.finalSizeIsKnown ? this.itemCount : -1));
        element.setAttribute("aria-posinset", String(index + 1));
        if (!element.id || element.id.startsWith(this.id + "_item_")) {
            element.id = this.id + "_item_" + index;
        }
        if (this.itemCount - 1 == index) {
            element.setAttribute("last", "");
        } else {
            element.removeAttribute("last");
        }

        this.updateSelectedAttribute(index, element);
        this.updateFocusedAttribute(index, element);

        return element;
    }

    private onScroll = () => {
        this._lastKnownScrollTop = this.scrollTop;
        const delta = this._lastRenderedScrollTop - this._lastKnownScrollTop;
        if (Math.abs(delta) >= this.itemHeight) {
            this._lastRenderedScrollTop = this._lastKnownScrollTop;
            this.requestUpdate();
        }
    };

    private updateFocusedAttribute(index: number, itemElement: HTMLElement) {
        if (this.focusIndex == index) {
            itemElement.setAttribute("focused", "");
            itemElement.toggleAttribute("focus-visible", this._focusVisibleIndex == index);
            this.setAttribute("aria-activedescendant", itemElement.id);
        } else {
            itemElement.removeAttribute("focused");
            itemElement.removeAttribute("focus-visible");
        }
    }

    private updateSelectedAttribute(index: number, itemElement: HTMLElement) {
        const selected = this.selectedIndices.indexOf(index) !== -1;
        if (selected) {
            itemElement.setAttribute("selected", "");
        } else {
            itemElement.removeAttribute("selected");
        }
        itemElement.setAttribute("aria-selected", String(selected));
    }

    private updateItemsRenderData(): void {
        this._itemsRenderData = [];
        this._visibleItemsNum = Math.min(Math.ceil(this.height / this.itemHeight), this.itemCount);

        if (this._visibleItemsNum > 0) {
            this._firstVisibleIndex = this.normalizeIndex(Math.floor(this._lastKnownScrollTop / this.itemHeight));
            this._lastVisibleIndex = this.normalizeIndex(this._firstVisibleIndex + this._visibleItemsNum);

            const firstRenderedIndex = this.normalizeIndex(this._firstVisibleIndex - 2);
            const lastRenderedIndex = this.normalizeIndex(this._lastVisibleIndex + 2);

            // May update value of this.items, which could trigger another render if not called from a lifecycle callback where it is ignored
            this.requestData(firstRenderedIndex, lastRenderedIndex);

            for (let i = firstRenderedIndex; i <= lastRenderedIndex; i++) {
                const physicalIndex = i - firstRenderedIndex;
                const itemData = this.items[physicalIndex];
                let dataHash;
                if (itemData) {
                    dataHash = JSON.stringify(itemData);
                } else {
                    dataHash = `placeholder-${physicalIndex}`;
                }
                this._itemsRenderData.push({
                    index: i,
                    top: this.itemHeight * i,
                    physicalIndex,
                    dataHash,
                    data: itemData,
                });
            }
        } else {
            this._firstVisibleIndex = 0;
            this._lastVisibleIndex = 0;
        }
    }

    private normalizeIndex(index: number): number {
        return Math.max(0, Math.min(index, this.itemCount - 1));
    }

    private get height(): number {
        return this.offsetHeight;
    }

    private requestData(firstRenderedIndex: number, lastRenderedIndex: number): void {
        if (!Number.isNaN(firstRenderedIndex) && !Number.isNaN(lastRenderedIndex)) {
            this.dispatchEvent(
                new CustomEvent("data-request", {
                    detail: {
                        startIndex: firstRenderedIndex,
                        stopIndex: lastRenderedIndex,
                    },
                })
            );
        }
    }

    private handleKeyDown = (event: KeyboardEvent) => {
        let shouldPrevent = true;
        switch (event.key) {
            case "Down":
            case "ArrowDown":
                this.focusIndex = this.normalizeIndex(this.focusIndex + 1);
                break;
            case "Up":
            case "ArrowUp":
                this.focusIndex = this.normalizeIndex(this.focusIndex - 1);
                break;
            case "Enter":
                this.handleSelection(this.focusIndex);
                break;
            case "End":
                this.focusIndex = this.itemCount - 1;
                break;
            case "PageDown":
                this.focusIndex = this.normalizeIndex(this.focusIndex + this._visibleItemsNum - 1);
                break;
            case "Home":
                this.focusIndex = 0;
                break;
            case "PageUp":
                this.focusIndex = this.normalizeIndex(this.focusIndex - this._visibleItemsNum + 1);
                break;
            default:
                shouldPrevent = false;
                break;
        }
        if (shouldPrevent) {
            this._focusVisibleIndex = this.focusIndex;
            event.preventDefault();
            event.stopPropagation();
        }
    };

    private handleSelection(index: number): void {
        if (index < 0 || this.itemCount <= index) {
            return;
        }
        const physicalIndex = index % this._visibleItemsNum;
        if (!this.items[physicalIndex]) {
            return;
        }
        let hasBeenSelected = true;
        if (this.selectionType !== SelectionType.TriggerOnly) {
            const existingIndex = this.selectedIndices.indexOf(index);
            hasBeenSelected = existingIndex == -1;
            if (hasBeenSelected) {
                if (this.selectionType === SelectionType.Single) {
                    this.selectedIndices = [index];
                } else {
                    this.selectedIndices.push(index);
                }
            } else {
                this.selectedIndices.splice(existingIndex, 1);
            }
            this.requestUpdate("selectedIndices"); // altering inside of an array does not retrigger an update
        }
        this.focusIndex = index;
        this.dispatchSelectionEvent(index, hasBeenSelected);
    }

    private dispatchSelectionEvent(index: number, selected: boolean): void {
        this.dispatchEvent(
            new CustomEvent("selection", {
                detail: { index, selected },
            })
        );
    }

    private handleClick = (event: MouseEvent) => {
        const clickedElement = event
            .composedPath()
            .find((target: HTMLElement) => target.hasAttribute && target.hasAttribute("item-index")) as HTMLElement;
        if (clickedElement) {
            this._focusVisibleIndex = -1;
            this.handleSelection(Number(clickedElement.getAttribute("item-index")));
        }
    };
}

VirtualList.ensureDefined();
