import { LitElement, html, PropertyValues, unsafeCSS, css } from "lit";
import { property } from "lit/decorators/property.js";
import "./tab";
import Tab from "./tab";
export { default as Tab } from "./tab";

const ResizeObserver = require("resize-observer-polyfill").default as {
    new (callback: ResizeObserverCallback): ResizeObserver;
};
const style = require("./tab-bar-style.scss");

const SCROLL_STEP = 40;

export default class TabBar extends LitElement {
    public static readonly ID: string = "sd-tab-bar";

    @property({ type: Boolean, reflect: true, attribute: "no-bar" })
    public noBar: boolean;
    @property({ type: Boolean, reflect: true, attribute: "no-slide" })
    public noSlide: boolean;
    @property({ type: Boolean, reflect: true })
    public vertical: boolean;
    @property({ type: Boolean, reflect: true, attribute: "show-counter" })
    public showCounter: boolean;

    public tabElements: Tab[];

    @property()
    private showLeftScrollButton: boolean;
    @property()
    private showRightScrollButton: boolean;

    private _focusedIndex: number;
    private _selectedIndex: number;
    private resizeObserver: ResizeObserver;
    private lastKnownWidth: number;
    private _tabButtonContainer: HTMLElement;

    static shadowRootOptions: ShadowRootInit = {
        ...LitElement.shadowRootOptions,
        delegatesFocus: true,
    };

    public connectedCallback() {
        super.connectedCallback();
        this.resizeObserver = new ResizeObserver(() => {
            this.debounce("update-scroll-button-visibility", () => {
                if (this.lastKnownWidth !== this.offsetWidth) {
                    this.lastKnownWidth = this.offsetWidth;
                    this.updateScrollButtonVisibility();
                }
            });
        });
        this.resizeObserver.observe(this);
    }

    public disconnectedCallback() {
        super.disconnectedCallback();
        this.resizeObserver.disconnect();
    }

    public firstUpdated(changedProperties: PropertyValues): void {
        super.firstUpdated(changedProperties);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        const ownerDocument = this.ownerDocument as any;
        if (ownerDocument.adoptedStyleSheets) {
            (this.shadowRoot as any).adoptedStyleSheets = [
                ...(this.shadowRoot as any).adoptedStyleSheets,
                ...ownerDocument.adoptedStyleSheets,
            ];
        }
        /* eslint-enable @typescript-eslint/no-explicit-any */

        const slot = this.shadowRoot.querySelector("slot");
        slot.addEventListener("slotchange", this.handleSlotChange);

        this.addEventListener("keydown", this.handleKeyDown);
    }

    static get styles() {
        return [
            css`
                ${unsafeCSS(style)}
            `,
        ];
    }

    // prettier-ignore
    public render() {
        return html`
            <div class="root">
                ${!this.vertical && this.showLeftScrollButton ? html`
                    <div class="scroll-button left" @pointerdown="${() => {
                            this.tabButtonContainer.scrollLeft -= SCROLL_STEP;
                        }}">
                        <div class="ripple"></div>
                        <svg><g><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></g></svg>
                    </div>` : ""}
                <div class="tab-button-container" @scroll="${this.updateScrollButtonVisibility}">
                    <slot></slot>
                    ${this.noBar ? "" : html` <div class="slider"></div>`}
                </div>
                ${!this.vertical && this.showRightScrollButton ? html`
                    <div class="scroll-button right" @pointerdown="${() => {
                            this.tabButtonContainer.scrollLeft += SCROLL_STEP;
                        }}">
                        <div class="ripple"></div>
                        <svg><g><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></g></svg>
                    </div>` : ""}
            </div>
        `;
    }

    public set selectedIndex(newValue: number) {
        if (this._selectedIndex !== newValue) {
            this.focusedIndex = newValue;
            this._selectedIndex = newValue;
            this.updateSelectionState();
        }
    }

    public get selectedIndex(): number {
        return this._selectedIndex;
    }

    private updateSelectionState(): void {
        if (this.tabElements) {
            this.tabElements.forEach((node: HTMLElement, ind: number) => {
                if (ind === this._selectedIndex) {
                    (node as Tab).selected = true;
                    if (this.slider) {
                        window.requestAnimationFrame(() => {
                            let offsetLeft = node.offsetLeft;
                            let offsetTop = node.offsetTop;

                            if (node.offsetParent != this._tabButtonContainer) {
                                offsetLeft -= this._tabButtonContainer.offsetLeft;
                                offsetTop -= this._tabButtonContainer.offsetTop;
                            }

                            const slider = this.slider;
                            if (this.vertical) {
                                slider.style.height = node.offsetHeight + "px";
                                slider.style.transform = `translateY(${offsetTop}px)`;
                            } else {
                                slider.style.width = node.offsetWidth + "px";
                                slider.style.transform = `translateX(${offsetLeft}px)`;
                            }
                        });
                    }
                } else {
                    (node as Tab).selected = false;
                }
            });
        }
    }

    public set focusedIndex(newValue: number) {
        if (this._focusedIndex !== newValue) {
            this._focusedIndex = newValue;
            this.updateFocusedState();
        }
    }

    public get focusedIndex(): number {
        return this._focusedIndex;
    }

    private updateFocusedState() {
        if (this.tabElements && this.focusedIndex >= 0 && this.focusedIndex < this.tabElements.length) {
            this.scrollToFocusedIfNeeded();
            this.tabElements[this.focusedIndex].focus();
        }
    }

    private get slider(): HTMLElement {
        return this.shadowRoot.querySelector(".slider");
    }

    private get tabButtonContainer(): HTMLElement {
        if (!this._tabButtonContainer) {
            this._tabButtonContainer = this.shadowRoot.querySelector(".tab-button-container");
        }
        return this._tabButtonContainer;
    }

    private scrollToFocusedIfNeeded(): void {
        if (this.tabElements && this.focusedIndex >= 0 && this.focusedIndex < this.tabElements.length) {
            const selectedNode = this.tabElements[this.focusedIndex] as HTMLElement;
            let nodeLeft: number = selectedNode.offsetLeft;
            if (selectedNode.offsetParent != this._tabButtonContainer) {
                nodeLeft -= this._tabButtonContainer.offsetLeft;
            }
            const nodeWidth: number = selectedNode.offsetWidth;
            const nodeRight: number = nodeLeft + nodeWidth;
            if (
                nodeLeft > this.tabButtonContainer.scrollLeft &&
                nodeRight < this.tabButtonContainer.scrollLeft + this.tabButtonContainer.offsetWidth
            ) {
                return;
            }
            if (this.tabButtonContainer.scrollLeft < nodeLeft) {
                this.tabButtonContainer.scrollLeft = nodeRight - this.tabButtonContainer.offsetWidth;
            } else if (this.tabButtonContainer.scrollLeft + this.tabButtonContainer.offsetWidth > nodeRight) {
                this.tabButtonContainer.scrollLeft = nodeLeft;
            }
        }
    }

    private handleSlotChange = () => {
        this.tabElements = this.shadowRoot
            .querySelector("slot")
            .assignedNodes()
            .filter((node) => node instanceof Tab) as Tab[];
        let index = 0;
        this.tabElements.forEach((tab) => {
            tab.vertical = this.vertical;
            tab.showCounter = this.showCounter;
            tab.index = index++;
            tab.addEventListener("focus", this.handleTabFocus);
            tab.addEventListener("selection", this.handleTabSelection);
        });
        this.updateScrollButtonVisibility();
        if (this.selectedIndex == null) {
            this.selectedIndex = 0;
        } else {
            this.updateSelectionState();
            this.updateFocusedState();
        }
    };

    private handleTabFocus = (event: FocusEvent) => {
        const index = (event.target as Tab).index;
        if (this.focusedIndex !== index) {
            this._focusedIndex = index;
            this.scrollToFocusedIfNeeded();
        }
    };

    private handleTabSelection = (event: CustomEvent) => {
        this.selectedIndex = (event.target as Tab).index;
        this.dispatchSelectionEvent(event.detail.anchor);
    };

    private handleKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Up":
            case "ArrowUp":
            case "Left":
            case "ArrowLeft":
                event.preventDefault();
                this.focusedIndex = Math.max(this.focusedIndex - 1, 0);
                break;
            case "Down":
            case "ArrowDown":
            case "Right":
            case "ArrowRight":
                event.preventDefault();
                this.focusedIndex = Math.min(this.focusedIndex + 1, this.tabElements.length - 1);
                break;
        }
    };

    private updateScrollButtonVisibility(): void {
        this.showLeftScrollButton = this.tabButtonContainer.scrollLeft !== 0;
        this.showRightScrollButton =
            this.tabButtonContainer.scrollLeft <
            this.tabButtonContainer.scrollWidth - this.tabButtonContainer.offsetWidth;
    }

    private dispatchSelectionEvent(selectedAnchor?: string): void {
        this.dispatchEvent(
            new CustomEvent("selection", {
                detail: {
                    selectedTab: {
                        index: this.selectedIndex,
                        tabElement: this.tabElements[this.selectedIndex],
                    },
                    anchor: selectedAnchor,
                },
            })
        );
    }

    private debounced = {};
    private debounce(id: string, actor: () => void) {
        if (this.debounced[id]) {
            return;
        }
        this.debounced[id] = true;
        requestAnimationFrame(() => {
            delete this.debounced[id];
            actor();
        });
    }
}

if (!customElements.get(TabBar.ID)) {
    customElements.define(TabBar.ID, TabBar);
}
