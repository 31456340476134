import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Spinner from 'react-bootstrap/Spinner'
import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import '@smartdesign/message'
import { useState } from 'react';
const infoIcon = require('@smartdesign/icons/toolbar/info.svg');
const timeIcon = require('@smartdesign/icons/toolbar/time-recording.svg');

function Message(props) {
    return (
        <Row style={{ margin: '1em' }}>
            {(props.data.origin === "ai") && <>
                <Col md="auto" style={{ paddingRight: "10em" }}>
                    <sd-message level="info" hidden-icon>
                        <span slot="description">
                            <b>WeGPT Base</b>:&nbsp;<br></br>
                            {(props.data.data.answer.trim().includes("#") || props.data.data.answer.trim().includes("`") || props.data.data.answer.trim().includes("*")) ?
                                <ReactMarkdown children={props.data.data.answer.trim()} className="prose" />
                                :
                                props.data.data.answer.trim()
                            }
                        </span>
                        {props.data.data.end == 1 && <sd-button slot="footer" icon={timeIcon.default} style={{ float: 'right', cursor: 'default' }}>{props.data.data.duration}s</sd-button>}
                    </sd-message>
                </Col>
                <Col></Col>
            </>
            }
            {(props.data.origin === "user") && <>
                <Col></Col>
                <Col md="auto" style={{ paddingLeft: "10em" }}>
                    <sd-message level="info" hidden-icon>
                        <span slot="description">
                            <b>User</b>:&nbsp;<br></br>
                            {props.data.data.trim()}
                        </span>
                    </sd-message>
                </Col>
            </>
            }
            {(props.data.origin === "pending") && <>
                <Col md="auto" style={{ paddingRight: "10em" }}>
                    <sd-message level="info" hidden-icon>
                        <span slot="description">
                            <b>WeGPT Base</b>:&nbsp;<br></br>
                            <Spinner style={{ marginTop: '1em' }} animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </span>
                    </sd-message>
                </Col>
                <Col></Col>
            </>
            }
            {(props.data.origin === "intro") && <>
                <Col md="auto" style={{ paddingRight: "10em" }}>
                    <sd-message level="info" hidden-icon>
                        <span slot="description">
                            <b>WeGPT Base</b>:&nbsp;<br></br>
                            Hello, <br /><br />
                            I am WeGPT Base, a large language model chatbot. <br />
                            Please be aware that as a language model I can only generate a response bit by bit based on how likely a next word is.
                            I cannot reflect on the correctness of my answer. Do not rely on the information, I give. <br /><br />
                            To provide feedback, feel free to use the <i>Feedback</i> button.
                        </span>
                    </sd-message>
                </Col>
                <Col></Col>
            </>
            }
        </Row>
    )
}
export default Message;