import VirtualList from "./virtual-list";

export class ListDataProvider {
    public onDataRequest: (page: number) => void;
    public currentPage = -1;

    private _finalSizeIsKnown = false;
    private _itemCache: unknown[] = [];
    private _lastRequestedFirstIndex = 0;
    private _lastRequestedLastIndex = 0;
    private _lastLoadedIndex = 0;
    private _list: VirtualList;
    private _itemCount: number;
    private _pendingDataRequest: boolean;

    constructor(public pageSize: number = 100, public preloadedItemsCount: number = 5) {
        this.itemCount = pageSize; // configure initial load
    }

    public get finalSizeIsKnown(): boolean {
        return this._finalSizeIsKnown;
    }

    public set finalSizeIsKnown(value: boolean) {
        this._finalSizeIsKnown = value;
        if (value) {
            this.itemCount = this._itemCache.length;
        }
        if (this._list) {
            this._list.finalSizeIsKnown = value;
        }
    }

    private get itemCount(): number {
        return this._itemCount;
    }

    private set itemCount(count: number) {
        this._itemCount = count;
        if (this._list) {
            this._list.itemCount = count;
        }
    }

    public get items(): unknown[] {
        return this._itemCache;
    }

    public set items(items: unknown[]) {
        this._itemCache = items;
        this.onItemsChange();
    }

    public addItems(items: unknown[]): void {
        this._itemCache = this._itemCache.concat(items);
        this.onItemsChange();
    }

    public connectList(list: VirtualList): void {
        if (this._list) {
            this._list.removeEventListener("data-request", this.handleListDataRequest);
        }
        this._list = list;
        list.itemCount = this.itemCount;
        list.addEventListener("data-request", this.handleListDataRequest);
        list.finalSizeIsKnown = this._finalSizeIsKnown;
    }

    private onItemsChange(): void {
        this._pendingDataRequest = false;
        this._lastLoadedIndex = this._itemCache.length - 1;
        if (this.finalSizeIsKnown) {
            this.itemCount = this._itemCache.length;
        } else if (this._lastLoadedIndex > this.itemCount) {
            this.itemCount = this._lastLoadedIndex;
        }
        if (this._list) {
            this._list.items = this._itemCache.slice(this._lastRequestedFirstIndex, this._lastRequestedLastIndex + 1);
        }
        this.currentPage = Math.max(this.currentPage, Math.round(this.items.length / this.pageSize) - 1);
    }

    private handleListDataRequest = (event: CustomEvent) => {
        const { startIndex, stopIndex } = event.detail;
        this._lastRequestedFirstIndex = startIndex;
        this._lastRequestedLastIndex = stopIndex;
        this._list.items = this._itemCache.slice(startIndex, stopIndex + 1);

        if (!this.finalSizeIsKnown && this._lastLoadedIndex < stopIndex + this.preloadedItemsCount) {
            this.requestData();
        }
    };

    private requestData(): void {
        if (this._pendingDataRequest) {
            return;
        }
        if (this.onDataRequest) {
            this._pendingDataRequest = true;
            this.onDataRequest(++this.currentPage);
            this._lastLoadedIndex += this.pageSize - 1;
            if (this._lastLoadedIndex > this.itemCount) {
                this.itemCount = this._lastLoadedIndex;
            }
        } else {
            throw Error(
                "The final size is not yet known and the list would require item data from index " +
                    this._lastRequestedFirstIndex +
                    ". to " +
                    this._lastRequestedLastIndex +
                    ". which is not possible to load without a configured onDataRequest"
            );
        }
    }
}
