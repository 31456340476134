(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@smartdesign/list-item"), require("lit"), require("lit/decorators/property.js"), require("resize-observer-polyfill"));
	else if(typeof define === 'function' && define.amd)
		define(["@smartdesign/list-item", "lit", "lit/decorators/property.js", "resize-observer-polyfill"], factory);
	else if(typeof exports === 'object')
		exports["@smartdesign/virtual-list"] = factory(require("@smartdesign/list-item"), require("lit"), require("lit/decorators/property.js"), require("resize-observer-polyfill"));
	else
		root["@smartdesign/virtual-list"] = factory(root["@smartdesign/list-item"], root["lit"], root["lit/decorators/property.js"], root["resize-observer-polyfill"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__774__, __WEBPACK_EXTERNAL_MODULE__870__, __WEBPACK_EXTERNAL_MODULE__961__, __WEBPACK_EXTERNAL_MODULE__866__) => {
return 