(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@popperjs/core"), require("@smartdesign/virtual-list"), require("lit"), require("lit/decorators/property.js"), require("resize-observer-polyfill"));
	else if(typeof define === 'function' && define.amd)
		define(["@popperjs/core", "@smartdesign/virtual-list", "lit", "lit/decorators/property.js", "resize-observer-polyfill"], factory);
	else if(typeof exports === 'object')
		exports["@smartdesign/tab-bar"] = factory(require("@popperjs/core"), require("@smartdesign/virtual-list"), require("lit"), require("lit/decorators/property.js"), require("resize-observer-polyfill"));
	else
		root["@smartdesign/tab-bar"] = factory(root["@popperjs/core"], root["@smartdesign/virtual-list"], root["lit"], root["lit/decorators/property.js"], root["resize-observer-polyfill"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__798__, __WEBPACK_EXTERNAL_MODULE__781__, __WEBPACK_EXTERNAL_MODULE__870__, __WEBPACK_EXTERNAL_MODULE__961__, __WEBPACK_EXTERNAL_MODULE__866__) => {
return 