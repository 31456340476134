export abstract class ElementBase extends HTMLElement {
    public abstract is(): string;

    protected abstract template(): HTMLTemplateElement;
    private static readonly TEMPLATE_CACHE: {
        [name: string]: HTMLTemplateElement;
    } = {};
    private memoizedTemplate(): HTMLTemplateElement {
        const is = this.is();
        if (ElementBase.TEMPLATE_CACHE[is]) {
            return ElementBase.TEMPLATE_CACHE[is];
        }
        const template = this.template();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).ShadyCSS) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).ShadyCSS.prepareTemplate(template, this.is());
        }
        ElementBase.TEMPLATE_CACHE[is] = template;
        return template;
    }

    public connectedCallback() {
        const template = this.memoizedTemplate();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).ShadyCSS) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).ShadyCSS.styleElement(this);
        }
        if (!this.shadowRoot) {
            this.attachShadow({ mode: "open" });
            this.shadowRoot.appendChild(document.importNode(template.content, true));
            requestAnimationFrame(() => this.dispatchEvent(new CustomEvent("ready")));
        }
    }

    public whenReady(actor: () => void) {
        if (this.shadowRoot) {
            actor();
        } else {
            this.addEventListener("ready", () => actor());
        }
    }
}
