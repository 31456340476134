import { LitElement, html, PropertyValues, unsafeCSS, TemplateResult, ComplexAttributeConverter, css } from "lit";
import { property } from "lit/decorators/property.js";
const style = require("./style.scss");

export default class SDFieldValidationMessage extends LitElement {
    public static readonly ID: string = "sd-field-validation-message";

    public static parseLevel(value: string): ValidationLevel {
        if (value) {
            const correspondingEnumKey = Object.keys(ValidationLevel).find(
                (level) => level.toLowerCase() === value.toLowerCase()
            );
            return correspondingEnumKey && ValidationLevel[correspondingEnumKey];
        }
    }
    public static levelConverter = {
        fromAttribute(value: string): ValidationLevel {
            return SDFieldValidationMessage.parseLevel(value);
        },
        toAttribute(value: ValidationLevel): string {
            return value && value.toLowerCase();
        },
    } as ComplexAttributeConverter;

    @property({ type: String, attribute: true })
    public message: string;
    @property({ type: String, attribute: true })
    public icon: string;
    @property({
        converter: SDFieldValidationMessage.levelConverter,
        reflect: true,
    })
    public level: ValidationLevel;

    private _defaultIconPath: string;

    static get styles() {
        return [
            css`
                ${unsafeCSS(style)}
            `,
        ];
    }

    // prettier-ignore
    render(): TemplateResult {
        return html`${this.iconToUse && html`<img class="icon" src="${this.iconToUse}">`}${this.message}`;
    }

    private get iconToUse(): string {
        return this.icon || this._defaultIconPath;
    }

    shouldUpdate(changedProperties: PropertyValues): boolean {
        if (changedProperties.has("level")) {
            this._defaultIconPath = this.iconForLevel;
            if (!this.icon) {
                return true;
            }
        }
        return super.shouldUpdate(changedProperties);
    }

    private get iconForLevel(): string {
        switch (SDFieldValidationMessage.parseLevel(this.level)) {
            case ValidationLevel.Warn:
                return require("./warn.svg");
            case ValidationLevel.Suggest:
                return require("./suggest.svg");
            case ValidationLevel.Error:
                return require("./error.svg");
            default:
                return null;
        }
    }
}

export enum ValidationLevel {
    Warn = "warn",
    Suggest = "suggest",
    Error = "error",
}

if (!customElements.get(SDFieldValidationMessage.ID)) {
    customElements.define(SDFieldValidationMessage.ID, SDFieldValidationMessage);
}
