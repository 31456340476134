import { LitElement, html, PropertyValues, unsafeCSS, TemplateResult, css, nothing } from "lit";
import { property } from "lit/decorators/property.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { ValidationLevel } from "@smartdesign/field-validation-message";

const style = require("./style.scss");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const delegatesFocus = "delegatesFocus" in (window as any).ShadowRoot.prototype;

let idCounter = 0;

export default class SDInput extends LitElement {
    public static readonly ID: string = "sd-lit-input";
    private static readonly DEFAULT_MAX_LENGTH: number = 524288;

    @property({ type: String, reflect: true })
    public label: string;
    @property({ type: String, attribute: true })
    public validationMessage: string;
    @property({ type: String, attribute: true })
    public validationIconSrc: string;
    @property({ type: ValidationLevel, attribute: true, reflect: true })
    public validationLevel: ValidationLevel;
    @property({ type: String })
    public currentText: string;
    @property({ type: Boolean, attribute: true })
    public alwaysFloatLabel: boolean;
    @property({ type: Boolean, attribute: true })
    public autocompleted: boolean;
    @property({ type: Number, attribute: true })
    public rows = 1;

    // delegated settings to input
    @property({ type: String, reflect: true })
    public type = "text";
    @property({ type: String, reflect: true })
    public placeholder: string;
    @property({ type: String, reflect: true })
    public sdAriaLabel: string;
    @property({ type: Number, reflect: true })
    public maxlength: number;
    @property({ type: Boolean, reflect: true })
    public disabled: boolean;
    @property({ type: Boolean, reflect: true })
    public readonly: boolean;
    @property({ type: Boolean, reflect: true })
    public required: boolean;
    @property({ type: String, reflect: true })
    public name: string;
    @property({ type: Boolean, reflect: true })
    public inactive: boolean;

    private _inputElement: HTMLInputElement;
    private _initialized: boolean;
    private _needsAutocompletedCheck: boolean;
    private _validationMessageId: string;
    private _inputId: string;

    static shadowRootOptions: ShadowRootInit = {
        ...LitElement.shadowRootOptions,
        delegatesFocus,
    };

    constructor() {
        super();
        this.initAutocompletedWithShadyDOM();
        const nextIdNumber = idCounter++;
        this._validationMessageId = SDInput.ID + "_message_" + nextIdNumber;
        this._inputId = SDInput.ID + "_input_" + nextIdNumber;
    }

    protected firstUpdated(changedProperties: PropertyValues): void {
        super.firstUpdated(changedProperties);
        this.updateInitialValue();

        this.inputElement.oninput = (event: InputEvent) => {
            this.autocompleted = "insertReplacementText" === event.inputType || !("data" in event);
            this.currentText = this.inputElement.value;
            this.fireValueChange(true);
        };
        this.inputElement.onchange = () => this.fireValueChange();
        if (!delegatesFocus) {
            // Provide a limited "delegatesFocus" behavior where it is not supported.
            // Note that clicking on non-focusable elements inside the shadow root
            // does not focus the first focusable element as it would have been expected.
            this.inputElement.onfocus = () => this.setAttribute("focused", "");
            this.inputElement.onblur = () => this.removeAttribute("focused");
            this.addEventListener("focus", (event) => {
                if (event.target === this) {
                    this.inputElement.focus();
                }
            });
        }
        this._initialized = true;
    }

    /**
     * If the input is used with active ShadyDOM, then it can be a potential target for autofill/autocomplete events.
     * As there is no native event dispatched in these cases, we have to:
     * - listen animationstart to support Chrome, Opera & Safari
     * - listen input events for other browsers (already done to update currentText)
     */
    private initAutocompletedWithShadyDOM() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const shadyDOM = (window as any).ShadyDOM;
        if (shadyDOM && shadyDOM.inUse) {
            this._needsAutocompletedCheck = true;
            shadyDOM.nativeMethods.addEventListener.call(this, "animationstart", (event) => {
                if (/^onautofillstart(-sd-lit-input-\d+|\s?)$/.test(event.animationName)) {
                    this.autocompleted = true;
                } else if (/^onautofillcancel(-sd-lit-input-\d+|\s?)$/.test(event.animationName)) {
                    this.autocompleted = false;
                }
            });
        }
    }

    private updateInitialValue(): void {
        if (typeof this.currentText !== "undefined") {
            this.value = this.currentText;
        } else {
            this.value = this.getAttribute("value");
        }
        if (this.value) {
            this.currentText = this.inputElement.value;
        }
    }

    // It is public as hacking around due to IE 11 is a common task to do.
    public get inputElement(): HTMLInputElement {
        if (this.shadowRoot && !this._inputElement) {
            this._inputElement = this.shadowRoot.querySelector(".input");
        }
        return this._inputElement;
    }

    public get value(): string {
        return this.inputElement && this.inputElement.value;
    }

    public set value(newValue: string) {
        this.currentText = newValue || "";
        if (this.inputElement) {
            this.inputElement.value = this.currentText;
        }
    }

    public get selectionStart(): number {
        return this.inputElement ? this.inputElement.selectionStart : 0;
    }

    public focus(): void {
        if (this.inputElement) {
            this.inputElement.focus();
        } else {
            super.focus();
        }
    }

    public select(): void {
        if (this.inputElement) {
            this.inputElement.select();
        }
    }

    public setSelectionRange(start: number, end: number): void {
        this.updateComplete.then(() => {
            if (this.inputElement) {
                this.inputElement.setSelectionRange(start, end);
            }
        });
    }

    static get styles() {
        return [
            css`
                ${unsafeCSS(style)}
            `,
        ];
    }

    public render(): TemplateResult {
        let inputPart;
        if (this.rows === 1) {
            inputPart = html`
                <input
                    id=${this._inputId}
                    class="input"
                    .type=${this.type}
                    placeholder=${ifDefined(this.placeholder || undefined)}
                    name=${ifDefined(this.name || undefined)}
                    aria-disabled=${this.disabled}
                    ?disabled=${this.inactive}
                    ?readonly=${this.readonly || this.disabled}
                    ?required=${this.required}
                    maxlength=${this.maxlength > 0 ? this.maxlength : SDInput.DEFAULT_MAX_LENGTH}
                    aria-describedby=${this._validationMessageId}
                    aria-invalid=${ifDefined(!!this.validationMessage || undefined)}
                    aria-label=${ifDefined(this.sdAriaLabel || undefined)}
                />
            `;
        } else {
            inputPart = html`
                <textarea
                    id=${this._inputId}
                    class="input"
                    placeholder=${ifDefined(this.placeholder || undefined)}
                    name=${ifDefined(this.name || undefined)}
                    aria-disabled=${this.disabled}
                    ?disabled=${this.inactive}
                    ?readonly=${this.readonly || this.disabled}
                    ?required=${this.required}
                    maxlength=${this.maxlength > 0 ? this.maxlength : SDInput.DEFAULT_MAX_LENGTH}
                    rows=${this.rows}
                    aria-describedby=${this._validationMessageId}
                    aria-invalid=${ifDefined(!!this.validationMessage || undefined)}
                    aria-label=${ifDefined(this.sdAriaLabel || undefined)}
                ></textarea>
            `;
        }
        return html`
            ${this.label ? html` <div class="floated-label-placeholder" aria-hidden="true">&nbsp;</div> ` : nothing}
            <div class="input-wrapper">
                <span class="prefix"><slot name="prefix"></slot></span>
                <div class="input-container" style="position:${this.shouldFloat() ? "static" : "relative"};">
                    ${this.label &&
                    html`
                        <label
                            for="${this._inputId}"
                            class="label ${this.shouldFloat() ? "float" : ""}"
                            aria-hidden="true"
                            >${this.label}</label
                        >
                    `}
                    ${inputPart}
                </div>
                <span class="suffix"><slot name="suffix"></slot></span>
            </div>
            <div class="underline" aria-hidden="true">
                <div class="unfocused-line"></div>
                <div class="focused-line"></div>
            </div>
            <div class="validation-message-wrapper" aria-hidden="true">
                ${this.validationMessage &&
                html`
                    <sd-field-validation-message
                        id=${this._validationMessageId}
                        class="validation-message"
                        .message=${this.validationMessage}
                        .icon=${this.validationIconSrc}
                        .level=${this.validationLevel}
                    >
                    </sd-field-validation-message>
                `}
            </div>
        `;
    }

    protected updated(_changedProperties: PropertyValues): void {
        super.updated(_changedProperties);
        if (this._needsAutocompletedCheck && !this.autocompleted) {
            setTimeout(() => {
                try {
                    // In certain cases there is no animation event dispatched even if the animation itself is done (it is visible also in dev tools) when an autofill is done.
                    this.autocompleted = this.autocompleted || !!this.shadowRoot.querySelector(":-webkit-autofill");
                } catch (ignored) {
                    // the above used selector is not supported for example in Firefox
                }
            }, 0);
        }
    }

    public update(changedProperties: PropertyValues): void {
        super.update(changedProperties);
        if (changedProperties.has("validationMessage")) {
            if (this.validationMessage) {
                if (!this.validationLevel) {
                    this.validationLevel = ValidationLevel.Error;
                }
            } else {
                this.validationLevel = null;
            }
        }
        if (this._initialized && changedProperties.has("rows")) {
            throw Error("rows attribute cannot be changed after the input is attached to the DOM");
        }
    }

    protected fireValueChange(immediate?: boolean): void {
        this.dispatchEvent(
            new CustomEvent(`${immediate ? "immediate-" : ""}value-change`, { detail: { value: this.value } })
        );
    }

    private shouldFloat() {
        return this.alwaysFloatLabel || this.currentText || this.placeholder || this.autocompleted;
    }
}

if (!customElements.get(SDInput.ID)) {
    customElements.define(SDInput.ID, SDInput);
}
