(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@smartdesign/field-validation-message"), require("@smartdesign/lit-input"), require("big.js"), require("hex-rgb"), require("lit"), require("lit/decorators/property.js"), require("resize-observer-polyfill"));
	else if(typeof define === 'function' && define.amd)
		define(["@smartdesign/field-validation-message", "@smartdesign/lit-input", "big.js", "hex-rgb", "lit", "lit/decorators/property.js", "resize-observer-polyfill"], factory);
	else if(typeof exports === 'object')
		exports["@smartdesign/lit-slider"] = factory(require("@smartdesign/field-validation-message"), require("@smartdesign/lit-input"), require("big.js"), require("hex-rgb"), require("lit"), require("lit/decorators/property.js"), require("resize-observer-polyfill"));
	else
		root["@smartdesign/lit-slider"] = factory(root["@smartdesign/field-validation-message"], root["@smartdesign/lit-input"], root["big.js"], root["hex-rgb"], root["lit"], root["lit/decorators/property.js"], root["resize-observer-polyfill"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__511__, __WEBPACK_EXTERNAL_MODULE__324__, __WEBPACK_EXTERNAL_MODULE__372__, __WEBPACK_EXTERNAL_MODULE__78__, __WEBPACK_EXTERNAL_MODULE__870__, __WEBPACK_EXTERNAL_MODULE__961__, __WEBPACK_EXTERNAL_MODULE__866__) => {
return 