export type ScrollToAlignment = "auto" | "center" | "start" | "end";

export interface IListUtil {
    getOffsetForIndexAndAlignment(
        index: number,
        alignment: ScrollToAlignment,
        scrollOffset: number,
        itemHeight: number,
        height: number,
        itemCount: number
    ): number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    debounce(func: (...args: any) => void): () => void;
}

class ListUtil implements IListUtil {
    public getOffsetForIndexAndAlignment(
        index: number,
        alignment: ScrollToAlignment,
        scrollOffset: number,
        itemHeight: number,
        height: number,
        itemCount: number
    ): number {
        const lastItemOffset = Math.max(0, itemCount * itemHeight);
        const maxOffset = Math.min(lastItemOffset, index * itemHeight);
        const minOffset = Math.max(0, index * itemHeight - height + itemHeight);

        switch (alignment) {
            case "start":
                return maxOffset;
            case "end":
                return minOffset;
            case "center": {
                const middleOffset = Math.round(minOffset + (maxOffset - minOffset) / 2);
                if (middleOffset < Math.ceil(height / 2)) {
                    return 0;
                } else if (middleOffset > lastItemOffset + Math.floor(height / 2)) {
                    return lastItemOffset;
                } else {
                    return middleOffset;
                }
            }
            case "auto":
            default:
                if (scrollOffset >= minOffset && scrollOffset <= maxOffset) {
                    return scrollOffset;
                } else if (scrollOffset < minOffset) {
                    return minOffset;
                } else {
                    return maxOffset;
                }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public debounce(func: (...args: any) => void): (...args: unknown[]) => void {
        let timer: number;
        return (...args: unknown[]) => {
            if (timer) {
                window.cancelAnimationFrame(timer);
            }
            timer = window.requestAnimationFrame(() => {
                func(...args);
                timer = null;
            });
        };
    }
}

export default new ListUtil();
