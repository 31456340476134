(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@popperjs/core"));
	else if(typeof define === 'function' && define.amd)
		define(["@popperjs/core"], factory);
	else if(typeof exports === 'object')
		exports["@smartdesign/popover"] = factory(require("@popperjs/core"));
	else
		root["@smartdesign/popover"] = factory(root["@popperjs/core"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__798__) => {
return 