import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import '@smartdesign/button'
import Chat from './components/chat/chat';
import Nav from './components/nav/nav';
import { createContext } from 'react';
import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'

import ChatHttpClient from './components/chat/chatHttpClient'

export const ChatHttpClientContext = createContext(ChatHttpClient)

function App() {
  return (
    <>
      <Nav />
      <ChatHttpClientContext.Provider value={new ChatHttpClient()}>
        <Chat />
      </ChatHttpClientContext.Provider>
    </>
  );
}

export default App;