(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@smartdesign/image-tools"), require("lit"), require("lit/decorators/property.js"), require("lit/directives/if-defined.js"), require("lit/directives/unsafe-html.js"));
	else if(typeof define === 'function' && define.amd)
		define(["@smartdesign/image-tools", "lit", "lit/decorators/property.js", "lit/directives/if-defined.js", "lit/directives/unsafe-html.js"], factory);
	else if(typeof exports === 'object')
		exports["@smartdesign/list-item"] = factory(require("@smartdesign/image-tools"), require("lit"), require("lit/decorators/property.js"), require("lit/directives/if-defined.js"), require("lit/directives/unsafe-html.js"));
	else
		root["@smartdesign/list-item"] = factory(root["@smartdesign/image-tools"], root["lit"], root["lit/decorators/property.js"], root["lit/directives/if-defined.js"], root["lit/directives/unsafe-html.js"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__707__, __WEBPACK_EXTERNAL_MODULE__870__, __WEBPACK_EXTERNAL_MODULE__961__, __WEBPACK_EXTERNAL_MODULE__949__, __WEBPACK_EXTERNAL_MODULE__409__) => {
return 