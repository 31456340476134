import axios from 'axios'
import { serverUrl } from '../../config/config'
const serverUrlDyn = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : serverUrl;

class ChatHttpClient {
    async sendMessage(content) {
        console.log(content);
        return axios.post(
            serverUrlDyn + "/chat",
            content
        ).then(res => res.data).catch(reas => null)

    }
    async updateMessage(content) {
        console.log(content);
        return axios.post(
            serverUrlDyn + "/fetch_stream",
            content
        ).then(res => res.data).catch(reas => null)
    }
}

export default ChatHttpClient;