import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import '@smartdesign/button'
import '@smartdesign/lit-input'
import '@smartdesign/lit-input'
import '@smartdesign/popover'
import '@smartdesign/checkbox'
import '@smartdesign/lit-slider'
//import '@smartdesign/icons'

import Message from "../message/message"
import { useContext, useEffect, useState, useRef } from "react"
import { ChatHttpClientContext } from "../../App"
const feedbackUrlDyn = process.env.REACT_APP_FEEDBACK_URL;
const cancelIcon = require('@smartdesign/icons/toolbar/cancel.svg');
const deleteIcon = require('@smartdesign/icons/toolbar/delete.svg');
const refreshIcon = require('@smartdesign/icons/toolbar/refresh.svg');
const messageIcon = require('@smartdesign/icons/toolbar/message-deactivated-suggestion.svg');
const ticketIcon = require('@smartdesign/icons/toolbar/ticket.svg');

function Chat() {
    const chatHttpClient = useContext(ChatHttpClientContext);
    const [enterToSend, setEnterToSend] = useState(true);
    const [useHistory, setUseHistory] = useState(true);
    const [chatMessages, setChatMessages] = useState([]);
    const [pendingMessage, setPendingMessage] = useState(null);
    const [generating, setGenerating] = useState(false);
    const generatingInterval = useRef();

    const handleEnter = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && enterToSend && !pendingMessage) {
            sendMessage(false);
        }
    }

    const sendMessage = (isRegenerate) => {
        let textMsg = "";
        let history = [];
        if (isRegenerate) {
            const resendMsg = chatMessages[chatMessages.length - 2];
            textMsg = resendMsg.data;
            const chatMessagesWithoutLast = chatMessages.slice(0, -2);
            history = chatMessagesWithoutLast;
            setChatMessages(chatMessagesWithoutLast);
        } else {
            const inputField = document.getElementById('messageBox');
            textMsg = inputField.value.trim();
            if (inputField.hasAttribute('disabled') || textMsg === "") {
                return;
            }
            inputField.value = "";
            history = chatMessages
        }
        const data = {
            msg: textMsg,
            history: []
        };
        const dataWHistory = {
            msg: textMsg,
            history: history

        }

        setPendingMessage({
            origin: 'user',
            data: textMsg
        });

        setGenerating(true);

        chatHttpClient.sendMessage((useHistory) ? dataWHistory : data).then(a => {
            generatingInterval.current = setInterval(() => {
                let response = chatHttpClient.updateMessage({ stream_id: a.id })
                response.then((value) => {
                    // Only update the message once the first result has been generated
                    if (value.answer != "") {
                        console.log(value);
                        setPendingMessage(null)
                        if (value.end == 1) {
                            setGenerating(false)
                            clearInterval(generatingInterval.current);
                        } else {
                            value.answer += "⚫";
                        }
                        setChatMessages([
                            ...history,
                            {
                                origin: 'user',
                                data: data.msg
                            },
                            {
                                origin: 'ai',
                                data: value
                            }
                        ]);
                    }

                });
            }, 250);
        });
    }


    const clearHistory = () => {
        setChatMessages([]);
    }

    const handleStopGeneration = () => {
        if (pendingMessage) {
            setPendingMessage(null);
            setChatMessages([
                ...chatMessages,
                {
                    origin: 'user',
                    data: pendingMessage.data
                },
                {
                    origin: 'ai',
                    data: {
                        answer: "Error: Generation was stopped.",
                        duration: 0,
                        end: 1,
                        id: 0
                    }
                }
            ]);
        }
        setGenerating(false);
        clearInterval(generatingInterval.current);
    }

    // scroll to end of chat when new message
    useEffect(() => {
        const chatWindow = document.getElementById('chatWindow');
        chatWindow.scrollTo({ top: chatWindow.scrollHeight, behavior: 'smooth' });
    }, [chatMessages, pendingMessage]);



    return (
        <Container style={{ padding: '1em', backgroundColor: 'white', boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)' }}>
            <Row style={{ height: '60vh' }}>
                <Col id="chatWindow" style={{ height: '60vh', overflowY: 'scroll' }}>
                    {(chatMessages && chatMessages.length > 0) || pendingMessage
                        ? <>
                            {chatMessages.map(m => (
                                <Message data={m}></Message>
                            ))}
                            {pendingMessage &&
                                <>
                                    <Message data={pendingMessage}></Message>
                                    <Message data={{ origin: "pending" }}></Message>
                                </>
                            }
                        </>
                        : <>
                            <Message data={{ origin: "intro" }}></Message>
                        </>
                    }

                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <sd-button style={{ marginTop: '1em', float: 'left' }} icon={deleteIcon.default} onClick={(chatMessages.length > 0) ? clearHistory : null} disabled={(chatMessages.length > 0 && !generating) ? null : 'true'}>Clear history</sd-button>
                    <sd-button style={{ marginTop: '1em', float: 'left' }} icon={refreshIcon.default} onClick={(chatMessages.length > 0) ? () => sendMessage(true) : null} disabled={(chatMessages.length > 0 && !generating) ? null : 'true'}>Regenerate last response</sd-button>
                    <sd-button style={{ marginTop: '1em', float: 'left' }} icon={cancelIcon.default} onClick={(generating) ? handleStopGeneration : null} disabled={(generating) ? null : 'true'}>Stop generating</sd-button>
                </Col>
            </Row>
            <Row>
                <sd-lit-input id="messageBox" label="Your Message" rows="3" type="text" onKeyPress={handleEnter} disabled={null}></sd-lit-input>
            </Row>
            <Row>
                <Col>
                    <sd-checkbox style={{ marginTop: '1em', float: 'left' }} label="Send on Enter (Shift + Enter for new line)" checked={enterToSend ? 'checked' : null} disabled={null} onClick={() => setEnterToSend(!enterToSend)}></sd-checkbox>
                </Col>
                <Col>
                    <sd-button id="sendButton" style={{ marginTop: '1em', float: 'right' }} icon={messageIcon.default} onClick={(!pendingMessage) ? () => sendMessage(false) : null} disabled={(!pendingMessage) ? null : 'true'}>Send</sd-button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <sd-checkbox style={{ marginTop: '1em', float: 'left' }} label="Use chat history as context (slower)" checked={useHistory ? 'checked' : null} disabled={null} onClick={() => setUseHistory(!useHistory)}></sd-checkbox>
                </Col>
                <Col>
                    {feedbackUrlDyn &&
                        <sd-button id="feedbackButton" style={{ marginTop: '1em', float: 'right' }} icon={ticketIcon.default} onClick={() => window.open(feedbackUrlDyn, '_blank')}>Feedback</sd-button>}
                </Col>
            </Row>
        </Container>
    );
}
export default Chat;