import { ElementBase } from "@smartdesign/element-base";
const htmlTemplate = require("!raw-loader!./message.html").default;

export class Message extends ElementBase {
    public static readonly ID: string = "sd-message";
    private infoImage: HTMLImageElement;
    private captionElement: HTMLElement;
    private descriptionElement: HTMLElement;
    private captionSlot: HTMLSlotElement;

    public set caption(caption: string) {
        if (caption) {
            this.setAttribute("caption", caption);
        } else {
            this.removeAttribute("caption");
        }
    }

    public get caption(): string {
        return this.getAttribute("caption");
    }

    public set description(description: string) {
        if (description) {
            this.setAttribute("description", description);
        } else {
            this.removeAttribute("description");
        }
    }

    public get description(): string {
        return this.getAttribute("description");
    }

    public get level(): Level {
        return this.getAttribute("level") as Level;
    }

    public set level(level: Level) {
        if (level) {
            this.setAttribute("level", level);
        } else {
            this.setAttribute("level", "info");
        }
    }

    public get iconAlt(): string {
        return this.getAttribute("icon-alt");
    }

    public set iconAlt(value: string) {
        if (value) {
            this.setAttribute("icon-alt", value);
        } else {
            this.removeAttribute("icon-alt");
        }
    }

    public get hiddenIcon(): boolean {
        return this.hasAttribute("hidden-icon") && this.getAttribute("hidden-icon") !== "false";
    }

    public set hiddenIcon(hiddenIcon: boolean) {
        if (hiddenIcon) {
            this.setAttribute("hidden-icon", "");
        } else {
            this.removeAttribute("hidden-icon");
        }
    }

    static get observedAttributes(): string[] {
        return ["level", "caption", "description", "icon-alt"];
    }

    is(): string {
        return Message.ID;
    }

    protected template(): HTMLTemplateElement {
        const template = document.createElement("template");
        template.innerHTML = htmlTemplate;
        return template;
    }

    constructor() {
        super();
        this.updateNoCaptionStyleName = this.updateNoCaptionStyleName.bind(this);
    }

    connectedCallback(): void {
        super.connectedCallback();

        if (!this.hasAttribute("level")) {
            this.level = Level.INFO;
        }

        this.infoImage = this.shadowRoot.querySelector("img");
        this.captionElement = this.shadowRoot.querySelector(".caption");
        this.descriptionElement = this.shadowRoot.querySelector(".description");
        this.captionSlot = this.shadowRoot.querySelector('slot[name="caption"]');

        this.captionSlot.addEventListener("slotchange", this.updateNoCaptionStyleName);
        this.render();
    }

    disconnectedCallback(): void {
        this.captionSlot.removeEventListener("slotchange", this.updateNoCaptionStyleName);
    }

    public attributeChangedCallback(): void {
        this.render();
    }

    protected render(): void {
        this.updateCaption();
        this.updateDescription();
        this.updateIcon();
    }

    private getPathToIcon(level: Level): string {
        return require(`./resources/${level}.svg`);
    }

    private updateCaption() {
        if (!this.captionElement) {
            return;
        }
        if (this.caption) {
            this.captionElement.innerText = this.caption;
        } else {
            this.captionElement.innerText = "";
        }
        this.updateNoCaptionStyleName();
    }

    private updateNoCaptionStyleName() {
        if (this.caption || this.captionSlot.assignedNodes().length > 0) {
            this.classList.remove("no-caption");
        } else {
            this.classList.add("no-caption");
        }
    }

    private updateDescription() {
        if (!this.descriptionElement) {
            return;
        }
        if (this.description) {
            this.descriptionElement.innerText = this.description;
        } else {
            this.descriptionElement.innerText = "";
        }
    }

    private updateIcon() {
        if (this.infoImage) {
            this.infoImage.src = this.getPathToIcon(this.level);
            this.infoImage.alt = this.iconAlt || "";
        }
    }
}

export enum Level {
    ERROR = "error",
    WARN = "warn",
    INFO = "info",
}

if (!customElements.get(Message.ID)) {
    customElements.define(Message.ID, Message);
}
