import { LitElement, html, unsafeCSS, PropertyValues, css } from "lit";
import { property } from "lit/decorators/property.js";
import { createPopper, Instance as Popper } from "@popperjs/core";
import "@smartdesign/virtual-list";
import VirtualList, { SelectionType } from "@smartdesign/virtual-list";

const style = require("./tab-style.scss");

const isIE11 = navigator.userAgent.indexOf("Trident") !== -1;

export default class Tab extends LitElement {
    public static readonly ID: string = "sd-tab";

    @property({ type: String })
    public text: string;
    @property({ type: Array })
    public anchors: string[];
    @property()
    public index: number;
    @property()
    public showCounter: boolean;

    @property({ type: Boolean, reflect: true, attribute: "anchors-shown" })
    private anchorsShown: boolean;

    public noInk: boolean;
    public vertical: boolean;

    public minimumOverlayWidth = 150;

    private showRipple: boolean;
    private popper: Popper;
    private list: VirtualList;
    private _cssMathMaxSupported: boolean;

    get selected(): boolean {
        return this.hasAttribute("selected");
    }

    set selected(newValue: boolean) {
        if (newValue) {
            this.setAttribute("selected", "");
        } else {
            this.removeAttribute("selected");
            if (this.shadowRoot && this.showRipple) {
                const rippleElement = this.shadowRoot.querySelector(".ripple") as HTMLElement;
                rippleElement.style.display = "none";
                this.showRipple = false;
            }
        }
    }

    public firstUpdated(changedProperties: PropertyValues): void {
        super.firstUpdated(changedProperties);
        const supportsCSS = window.CSS && window.CSS.supports;
        this._cssMathMaxSupported = supportsCSS && CSS.supports("max-width", "max(50vw)");

        /* eslint-disable @typescript-eslint/no-explicit-any */
        const ownerDocument = this.ownerDocument as any;
        if (ownerDocument.adoptedStyleSheets) {
            (this.shadowRoot as any).adoptedStyleSheets = [
                ...(this.shadowRoot as any).adoptedStyleSheets,
                ...ownerDocument.adoptedStyleSheets,
            ];
        }
        /* eslint-enable @typescript-eslint/no-explicit-any */

        this.addEventListener("pointerdown", (event: PointerEvent) => {
            if (!this.noInk && !this.showRipple) {
                this.showRipple = true;
                const rect: DOMRect = this.getBoundingClientRect();
                const rippleElement = this.shadowRoot.querySelector(".ripple") as HTMLElement;
                rippleElement.style.left = `${event.clientX - rect.left}px`;
                rippleElement.style.top = `${event.clientY - rect.top}px`;
                rippleElement.style.display = "";
                rippleElement.addEventListener(
                    "animationend",
                    () => {
                        rippleElement.style.display = "none";
                        this.showRipple = false;
                    },
                    { once: true }
                );
            }
        });
        this.addEventListener("click", (event: MouseEvent) => {
            if (event.composedPath().indexOf(this.list) === -1) {
                this.dispatchSelectionEvent();
                event.preventDefault();
                event.stopPropagation();
            }
        });
        this.addEventListener("keydown", this.handleKeyDown);
        if (!this.hasAttribute("tabindex")) {
            this.setAttribute("tabindex", "0");
        }
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback();
        this.hideAnchors();
    }

    static get styles() {
        return [
            css`
                ${unsafeCSS(style)}
            `,
        ];
    }

    public render() {
        return html`
            <div class="root" @pointerover=${this.showAnchors} @pointerdown=${this.showAnchors}>
                ${this.showCounter ? html` <span class="counter">${this.index + 1}</span> ` : ""}
                <div class="text">${this.text}</div>
                <div class="ripple" style="display: none"></div>
                ${this.hasAnchors
                    ? html`
                          <svg class="down-arrow" tabindex="-1" viewBox="0 0 16 16" width="16" height="16">
                              <path d="M13 4v2l-5 5-5-5v-2l5 5z"></path>
                          </svg>
                      `
                    : ""}
            </div>
        `;
    }

    private handleKeyDown = (event: KeyboardEvent) => {
        if (this.hasAnchors) {
            const anchorTriggerKeys = this.vertical
                ? ["Left", "ArrowLeft", "Right", "ArrowRight", "Enter"]
                : ["Down", "ArrowDown", "Up", "ArrowUp", "Enter"];
            if (anchorTriggerKeys.indexOf(event.key) === -1) {
                this.hideAnchors();
                this.focus();
            } else {
                if (!this.anchorsShown) {
                    this.showAnchors();
                    window.requestAnimationFrame(() => {
                        // IE would scroll down to bottom because the anchor list has not yet positioned there.
                        this.list.focusIndex = 0;
                        this.list.focus();

                        this.updateDropdownSizes();
                    });
                }
                event.preventDefault();
                event.stopPropagation();
            }
        } else if (event.key === "Enter") {
            this.dispatchSelectionEvent();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    private updateDropdownSizes(): void {
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        const overlayMaxHeight = (viewportHeight - this.offsetHeight) * 0.5;
        Object.assign(this.list.style, {
            maxHeight: `${overlayMaxHeight}px`,
            minWidth: `${Math.max(this.offsetWidth, this.minimumOverlayWidth)}px`,
            maxWidth: this._cssMathMaxSupported
                ? `max(50vw, ${this.offsetWidth}px)`
                : Math.max((window.innerWidth || document.documentElement.clientWidth) / 2, this.offsetWidth) + "px",
        });
    }

    private handleListSelection = (event: CustomEvent) => {
        this.hideAnchors();
        event.stopPropagation();
        this.dispatchSelectionEvent(this.anchors[event.detail.index]);
    };

    private dispatchSelectionEvent(selectedAnchor?: string): void {
        this.dispatchEvent(new CustomEvent("selection", { detail: { anchor: selectedAnchor } }));
        this.focus();
    }

    private handlePointerLeave = (event: PointerEvent) => {
        if (isIE11 || (this.list && !this.list.contains(event.target as HTMLElement))) {
            window.addEventListener("pointermove", this.handlePointerMove);
        }
    };

    private handlePointerMove = (event: PointerEvent) => {
        if (!this.isPointerAbove(event, this) && !this.isPointerAbove(event, this.list)) {
            this.hideAnchors();
            window.removeEventListener("pointermove", this.handlePointerMove);
        }
    };

    private isPointerAbove(event: PointerEvent, element: HTMLElement): boolean {
        const rect = element.getBoundingClientRect();
        return (
            rect.left <= event.clientX &&
            rect.right >= event.clientX &&
            rect.top <= event.clientY &&
            rect.bottom >= event.clientY
        );
    }

    private showAnchors() {
        if (!this.anchorsShown && this.hasAnchors) {
            this.root.addEventListener("pointerleave", this.handlePointerLeave);
            if (!this.list) {
                this.createList();
            }
            this.list.items = this.anchors.map((anchor, index) => {
                return {
                    caption: anchor,
                    index,
                };
            });
            this.list.itemCount = this.list.items.length;
            this.updateDropdownSizes();
            this.ownerDocument.body.appendChild(this.list);
            if (!this.popper) {
                this.popper = createPopper(this, this.list, {
                    placement: this.vertical ? "right-start" : "bottom-start",
                    modifiers: [
                        {
                            name: "computeStyles",
                            options: {
                                gpuAcceleration: !isIE11,
                            },
                        },
                        {
                            name: "hide",
                            enabled: false,
                        },
                        {
                            name: "preventOverflow",
                            options: {
                                padding: 0,
                            },
                        },
                        {
                            name: "adjustWidthIfNeeded",
                            enabled: true,
                            phase: "read",
                            fn({ state }) {
                                const list = state.elements.popper as VirtualList;
                                list.increaseWidthOnNextRenderIfNeeded();
                            },
                        },
                    ],
                });
            }

            window.requestAnimationFrame(() => {
                if (this.popper) {
                    this.popper.update();
                }
            });

            this.anchorsShown = true;
        }
    }

    private createList(): void {
        this.list = document.createElement(VirtualList.ID) as VirtualList;
        this.list.classList.add("anchor-list");
        this.list.tabIndex = 0;
        this.list.selectionType = SelectionType.TriggerOnly;
        this.list.addEventListener("selection", this.handleListSelection);
        this.list.addEventListener("keydown", (e) => {
            if (e.key == "Escape") {
                this.hideAnchors();
                this.focus();
            }
        });
        this.list.addEventListener("focusout", () => {
            this.hideAnchors();
        });
        this.list.setAttribute("slot", "anchors");
        this.list.itemHeight = 50;
        Object.assign(this.list.style, {
            zIndex: "21000",
            boxShadow: `rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
                        rgba(0, 0, 0, 0.12) 0px 1px 5px 0px,
                        rgba(0, 0, 0, 0.2) 0px 3px 1px -2px`,
            background: "white",
            overflowY: "auto",
        });
    }

    private hideAnchors(): void {
        if (this.anchorsShown) {
            this.anchorsShown = false;
            this.root.removeEventListener("pointerleave", this.handlePointerLeave);
            if (this.list) {
                this.list.focusIndex = -1;
                this.list.parentElement.removeChild(this.list);
            }
        }
        if (this.popper) {
            this.popper.destroy();
            this.popper = null;
        }
    }

    private get hasAnchors(): boolean {
        return !!(this.anchors && this.anchors.length);
    }

    private get root(): HTMLElement {
        if (this.shadowRoot) {
            return this.shadowRoot.querySelector(".root");
        }
        return null;
    }
}

VirtualList.ensureDefined();
if (!customElements.get(Tab.ID)) {
    customElements.define(Tab.ID, Tab);
}
