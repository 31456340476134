import { LitElement, html, PropertyValues, unsafeCSS, TemplateResult, css } from "lit";
import { property } from "lit/decorators/property.js";
import ColorBlender from "./colorblender";
import { ValidationLevel } from "@smartdesign/field-validation-message";
import SDInput from "@smartdesign/lit-input";
import "@smartdesign/lit-input";
import { Big } from "big.js";

const ResizeObserver = require("resize-observer-polyfill").default as {
    new (callback: ResizeObserverCallback): ResizeObserver;
};
const style = require("./style.scss");

interface IEventListenerRegistration {
    remove();
}

export default class Slider extends LitElement {
    public static readonly ID: string = "sd-lit-slider";

    @property({ type: Number })
    private thumbPosition = 0;
    private _value = 0;
    private _relativeValue = 0;

    @property({ type: Number, reflect: true })
    public min = 0;
    @property({ type: Number, reflect: true })
    public max = 1;
    @property({ type: Number, reflect: true })
    public step: number;
    @property({ type: Boolean, reflect: true })
    public editable: boolean;
    @property({ type: String, reflect: true })
    public inputSuffix: string;
    @property({ type: Boolean, reflect: true })
    public disabled: boolean;
    @property({ type: String, attribute: true })
    public validationMessage: string;
    @property({ type: String, attribute: true })
    public validationIconSrc: string;
    @property({ type: String, attribute: true })
    public validationLevel: ValidationLevel;
    @property({ type: String, attribute: true })
    public decimalSeparator: string;

    @property({ type: Boolean })
    private active: boolean;

    private colorBlender: ColorBlender = new ColorBlender();
    private static defaultColor = "rgb(20, 103, 186)";
    @property()
    private baseColor: string = Slider.defaultColor;

    private resizeObserver: ResizeObserver;
    private lastKnownWidth: number;
    private _trackContainer: HTMLElement;
    private _inputElement: SDInput;
    private updateAlreadyRequested: boolean;

    constructor() {
        super();
        this.resizeObserver = new ResizeObserver(() => {
            window.requestAnimationFrame(() => {
                if (this.lastKnownWidth !== this.trackContainerWidth) {
                    this.lastKnownWidth = this.trackContainerWidth;
                    this.doUpdateRelativeValue(this._relativeValue, true);
                }
            });
        });
    }

    public firstUpdated(changedProperties) {
        super.firstUpdated(changedProperties);

        this.updateInitialValue();

        this.addEventListener(
            this.useTouchEvents() ? "touchstart" : "pointerdown",
            (event: TouchEvent | PointerEvent) => {
                if (!this.hasAttribute("disabled") && event.composedPath().indexOf(this.inputElement) === -1) {
                    event.preventDefault();
                    if ((event as PointerEvent).pointerId) {
                        this.setPointerCapture((event as PointerEvent).pointerId);
                    }
                    this.focus();
                    this.listenMoveEvents(
                        (event as PointerEvent).clientX || (event as TouchEvent).changedTouches[0].clientX
                    );
                }
            }
        );
        this.addEventListener("keydown", this.handleKeyDown, true);
        this.setAttribute("aria-valuemin", this.min.toString());
        this.setAttribute("aria-valuemax", this.max.toString());
        if (!this.hasAttribute("tabindex")) {
            this.setAttribute("tabindex", "0");
        }
    }

    public connectedCallback() {
        super.connectedCallback();
        ["colors", "min", "max", "step", "editable", "inputSuffix", "value"].forEach((p) => {
            this.upgradeProperty(p);
        });
        this.resizeObserver.observe(this);
    }

    public disconnectedCallback() {
        super.disconnectedCallback();
        this.lastKnownWidth = undefined;
        this.resizeObserver.disconnect();
    }

    private upgradeProperty(propertyName: string): void {
        if (Object.prototype.hasOwnProperty.call(this, propertyName)) {
            const value = this[propertyName];
            delete this[propertyName];
            this[propertyName] = value;
        }
    }

    private updateInitialValue(): void {
        const initialValue = Number.parseFloat(this.getAttribute("value")) || this._value;
        if (this.setValueInternal(initialValue)) {
            if (this.inputElement) {
                this.inputElement.value = this.formattedValue();
            }
            this.fireBothValueChangeEvents();
        }
    }

    protected shouldUpdate(_changedProperties: PropertyValues): boolean {
        if (_changedProperties.has("step")) {
            // Ensure value is rounded based on step.
            if (this.setValueInternal(this._value)) {
                this.fireBothValueChangeEvents();
            }
        }
        return super.shouldUpdate(_changedProperties);
    }

    public get value(): number {
        return this._value;
    }

    public set value(newValue: number) {
        const hasChanged = this.setValueInternal(newValue);
        if (hasChanged) {
            this.fireBothValueChangeEvents();
        }
    }

    private roundToStep(rawValue: number): number {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let roundedValue = Number.parseFloat(rawValue as any);
        if (Number.isNaN(roundedValue)) {
            return this._value;
        }
        if (this.step) {
            const coercedBigDecimal = Big(roundedValue).div(this.step).round().mul(this.step);
            roundedValue = Number.parseFloat(coercedBigDecimal.toString());
        }
        return roundedValue;
    }

    private setValueInternal(newValue: number): boolean {
        const roundedValue = this.roundToStep(newValue);
        if (this._value !== roundedValue) {
            const oldValue = this._value;
            this._value = roundedValue;
            this.relativeValue = (roundedValue - this.min) / (this.max - this.min);
            this.setAttribute("aria-valuenow", this._value.toString());
            this.requestUpdate("value", oldValue);
            return true;
        }
        return false;
    }

    get relativeValue() {
        return this._relativeValue;
    }

    set relativeValue(newRelativeValue: number) {
        if (this._relativeValue !== newRelativeValue) {
            this.doUpdateRelativeValue(newRelativeValue);
        }
    }

    private doUpdateRelativeValue(newRelativeValue: number, skipColorUpdate?: boolean) {
        this._relativeValue = newRelativeValue;
        this.thumbPosition = Math.min(Math.max(0, newRelativeValue), 1) * this.trackContainerWidth;
        const newValueBasedOnRelativeValue = this.min + (this.max - this.min) * this._relativeValue;
        this.setValueInternal(newValueBasedOnRelativeValue);
        if (!skipColorUpdate && this.colors && this.colors.size) {
            this.baseColor = this.colorBlender.blend(this._relativeValue);
        }
    }

    public get colors(): Map<number, string> {
        return this.colorBlender.colors;
    }

    public set colors(newColors: Map<number, string>) {
        this.colorBlender.colors = newColors;
        if (this.colors && this.colors.size) {
            this.baseColor = this.colorBlender.blend(this._relativeValue);
        } else {
            this.baseColor = Slider.defaultColor;
        }
    }

    static get styles() {
        return [
            css`
                ${unsafeCSS(style)}
            `,
        ];
    }

    public render(): TemplateResult {
        const trackBackgroundColor = this.colorBlender.transparentize(this.baseColor, 0.26);
        const trackContainerStyle = `background-color:${trackBackgroundColor}; right:${this.editable ? "58px" : "0"}`;
        const trackStyle = `transform: scaleX(${this.relativeValue}); background-color:${this.baseColor};`;
        const thumbStyle = `transform: translateX(${this.thumbPosition}px); background-color:${this.baseColor}; border-color:${trackBackgroundColor};`;
        return html`
            <div id="track-container" style="${trackContainerStyle}">
                <div id="track" style="${trackStyle}"></div>
            </div>
            <div id="thumb" ?active="${this.active}" style="${thumbStyle}"></div>
            ${this.editable &&
            html`
                <sd-lit-input
                    .value="${this.formattedValue()}"
                    @value-change="${this.onInputValueChange}"
                    ?disabled="${this.disabled}"
                    ><span slot="suffix">${this.inputSuffix}</span>
                </sd-lit-input>
            `}
            ${this.validationMessage &&
            html`
                <sd-field-validation-message
                    .message="${this.validationMessage}"
                    .icon="${this.validationIconSrc}"
                    .level="${this.validationLevel}"
                >
                </sd-field-validation-message>
            `}
        `;
    }

    private listenMoveEvents(currentClientX: number) {
        this.active = true;
        this.onPointerMove(currentClientX);

        const eventListenersToRemove: IEventListenerRegistration[] = [];
        const pointerMoveListener = (event) => {
            event.stopPropagation();
            event.preventDefault();
            this.onPointerMove(event.clientX || (event.changedTouches && event.changedTouches[0].clientX));
        };
        const pointerUpListener = () => {
            this.active = false;
            eventListenersToRemove.forEach((reg) => reg.remove());
            this.fireValueChange();
        };
        if (this.useTouchEvents()) {
            eventListenersToRemove.push(this.addPointerEventListener("touchmove", pointerMoveListener));
            eventListenersToRemove.push(this.addPointerEventListener("touchend", pointerUpListener));
            eventListenersToRemove.push(this.addPointerEventListener("touchcancel", pointerUpListener));
        } else {
            eventListenersToRemove.push(this.addPointerEventListener("pointermove", pointerMoveListener));
            eventListenersToRemove.push(this.addPointerEventListener("pointerup", pointerUpListener));
        }
    }

    private onPointerMove(clientX: number): void {
        if (this.updateAlreadyRequested) {
            return;
        }
        this.updateAlreadyRequested = true;
        window.requestAnimationFrame(async () => {
            this.updateAlreadyRequested = false;
            this.updateThumbPosition(clientX);
            const previousValue = this.relativeValue;
            this.relativeValue = this.thumbPosition / this.trackContainerWidth;
            if (this.relativeValue !== previousValue) {
                await this.updateComplete;
                this.fireValueChange(true);
                if (!this.active) {
                    this.fireValueChange();
                }
            }
        });
    }

    private addPointerEventListener(type: string, listener: EventListener): IEventListenerRegistration {
        window.addEventListener(type, listener, true);
        return {
            remove: () => {
                window.removeEventListener(type, listener, true);
            },
        };
    }

    private updateThumbPosition(clientX: number): void {
        this.thumbPosition = Math.min(
            this.trackContainerWidth,
            Math.max(0, clientX - this.getBoundingClientRect().left)
        );
        if (this.max - this.min > 1) {
            // Enforce integer steps by pointer move events. Fixes #231868
            const thumbStepForIntegerOffset = this.trackContainerWidth / (this.max - this.min);
            const thumbPositionError = this.thumbPosition % thumbStepForIntegerOffset;
            if (thumbPositionError) {
                if (thumbPositionError < thumbStepForIntegerOffset / 2) {
                    this.thumbPosition -= thumbPositionError;
                } else {
                    this.thumbPosition += thumbStepForIntegerOffset - thumbPositionError;
                }
            }
        }
    }

    private onInputValueChange(event: CustomEvent) {
        this.value = Number(event.detail.value.replace(RegExp(this.escapedDecimalSeparator), "."));
        if (String(this.value) !== String(event.detail.value)) {
            (event.target as SDInput).value = String(this.value);
        }
    }

    private formattedValue(): string {
        return this.value.toString().replace(/\./, this.decimalSeparatorOrDefault);
    }

    private get escapedDecimalSeparator(): string {
        return this.decimalSeparatorOrDefault.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    }

    private get decimalSeparatorOrDefault(): string {
        return this.decimalSeparator || Number(1.1).toLocaleString(this.navigatorLanguage).substring(1, 2);
    }

    private get navigatorLanguage(): string {
        if (navigator.languages && navigator.languages.length) {
            return navigator.languages[0];
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (navigator as any).userLanguage || navigator.language || (navigator as any).browserLanguage || "en";
        }
    }

    private handleKeyDown(event: KeyboardEvent): void {
        if (!this.hasAttribute("disabled") && event.composedPath().indexOf(this.inputElement) === -1) {
            let offset = this.step || (this.max - this.min) / 100;
            if (this.max - this.min > 1) {
                // Enforce integer steps by keydown events. Fixes #231868
                offset = Math.round(offset) || 1;
            }
            switch (event.keyCode) {
                case 37:
                case 40:
                    this.value = Math.max(this.value - offset, this.min);
                    event.preventDefault();
                    break;
                case 38:
                case 39:
                    this.value = Math.min(this.value + offset, this.max);
                    event.preventDefault();
                    break;
            }
        }
    }

    private get inputElement(): SDInput {
        if (!this._inputElement) {
            this._inputElement = this.shadowRoot.querySelector("sd-lit-input");
        }
        return this._inputElement;
    }

    private get trackContainer(): HTMLElement {
        if (!this._trackContainer) {
            this._trackContainer = this.shadowRoot.querySelector("#track-container");
        }
        return this._trackContainer;
    }

    private get trackContainerWidth(): number {
        return this.trackContainer && this.trackContainer.offsetWidth;
    }

    private fireBothValueChangeEvents(): void {
        this.fireValueChange(true);
        this.fireValueChange();
    }

    private fireValueChange(immediate?: boolean): void {
        this.dispatchEvent(
            new CustomEvent(`${immediate ? "immediate-" : ""}value-change`, { detail: { value: this.value } })
        );
    }

    private useTouchEvents(): boolean {
        return this.hasAttribute("use-touch-events");
    }
}

if (!customElements.get(Slider.ID)) {
    customElements.define(Slider.ID, Slider);
}
