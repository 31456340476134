import { LitElement, html, unsafeCSS, PropertyValues, css, nothing, TemplateResult } from "lit";
import { property } from "lit/decorators/property.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { ifDefined } from "lit/directives/if-defined.js";
import ImageTools from "@smartdesign/image-tools";

const styles = require("./styles.scss");

export interface ItemData {
    caption: string;
    description: string;
    leftContentGenerator?: (data: ItemData, element: ListItem) => HTMLElement;
    rightContentGenerator?: (data: ItemData, element: ListItem) => HTMLElement;
    icon?: string;
    iconBackgroundColor?: string;
    iconPlaceholder?: string;
    level?: number;
    contentMode?: ContentMode;
}

export type ContentMode = "text" | "html";

let isPointerEventSupported = true;

try {
    new PointerEvent("");
} catch (error) {
    isPointerEventSupported = false;
}

export default class ListItem extends LitElement {
    public static readonly ID: string = "sd-list-item";
    public static ensureDefined = (): void => {
        if (!customElements.get(ListItem.ID)) {
            customElements.define(ListItem.ID, ListItem);
        }
    };

    @property({ type: String })
    public caption: string;
    @property({ type: String })
    public description: string;
    @property({ type: Boolean })
    public selected: boolean;
    @property({ type: String })
    public icon: string;
    @property({ type: String })
    public iconBackgroundColor: string;
    @property({ type: String })
    public iconPlaceholder: string;
    @property({ type: Number })
    public level: number;
    @property()
    public contentMode: ContentMode = "text";
    @property({ type: Boolean, reflect: true, attribute: "enable-line-clamp" })
    public enableLineClamp: boolean;

    // aria attributes
    @property({ type: String, attribute: "icon-attr-aria-label" })
    public iconAttrAriaLabel: string;
    @property({ type: String, reflect: true })
    public role = "option";

    // title attributes
    @property({ type: String, attribute: "caption-attr-title" })
    public captionAttrTitle: string;
    @property({ type: String, attribute: "description-attr-title" })
    public descriptionAttrTitle: string;
    @property({ type: String, attribute: "icon-attr-title" })
    public iconAttrTitle: string;

    static get styles() {
        return [
            css`
                ${unsafeCSS(styles)}
            `,
        ];
    }

    public render(): TemplateResult {
        return html`
            <div class="container">
                ${this.getLevelIndicators().map(
                    (_, level) => html` <div class="level-indicator level-${level}"></div> `
                )}
                <div class="side-content">
                    <slot name="left-content"></slot>
                    ${this.renderIcon()}
                </div>
                <div class="labels">
                    ${this.renderLabel("caption", this.caption, this.captionAttrTitle)}
                    ${this.renderLabel("description", this.description, this.descriptionAttrTitle)}
                </div>
                <div class="side-content">
                    <slot name="right-content"></slot>
                </div>
            </div>
        `;
    }

    private renderIcon() {
        if (this.icon || this.iconPlaceholder) {
            const iconBackgroundStyle =
                this.iconBackgroundColor != null ? `background-color:${this.iconBackgroundColor}` : undefined;
            return html`
                <div
                    class="icon-wrapper"
                    @pointerdown="${this.handleIconClick}"
                    style="${ifDefined(iconBackgroundStyle)}"
                    role="img"
                    aria-label="${ifDefined(this.iconAttrAriaLabel)}"
                    title="${ifDefined(this.iconAttrTitle)}"
                >
                    <div class="icon"></div>
                </div>
            `;
        }
        return nothing;
    }

    private renderLabel(className: string, value: string, title: string) {
        return value
            ? html`
                  <div class="${className}" title="${ifDefined(title)}">
                      ${this.contentMode === "html" ? unsafeHTML(value) : value}
                  </div>
              `
            : nothing;
    }

    public updated(changedProperties: PropertyValues): void {
        super.updated(changedProperties);
        if (
            (changedProperties.has("icon") || changedProperties.has("iconPlaceholder")) &&
            (this.icon || this.iconPlaceholder)
        ) {
            ImageTools.showImage(this.shadowRoot.querySelector(".icon"), this.icon, this.iconPlaceholder);
        }
        if (changedProperties.has("selected")) {
            this.setAttribute("aria-selected", String(this.selected));
        }
    }

    /**
     * Returns the width in pixel which is missing to show the caption and description without ellipsis.
     */
    public get missingWidthForTexts(): number {
        const originalLineClamp = this.enableLineClamp;
        this.removeAttribute("enable-line-clamp"); // Need to remove immediately, otherwise the calculation may fail.

        let requiredWidth = 0;

        const captionElement = this.shadowRoot.querySelector(".caption");
        if (captionElement) {
            requiredWidth += captionElement.scrollWidth - captionElement.clientWidth;
        }

        const descriptionElement = this.shadowRoot.querySelector(".description");
        if (descriptionElement) {
            requiredWidth = Math.max(requiredWidth, descriptionElement.scrollWidth - descriptionElement.clientWidth);
        }
        if (requiredWidth > 0) {
            requiredWidth++; // Additional one pixel to ensure ellipsis is not needed anymore.
        }
        if (originalLineClamp) {
            this.setAttribute("enable-line-clamp", "");
        }
        return requiredWidth;
    }

    private handleIconClick(event: PointerEvent): void {
        event.preventDefault();
        this.dispatchEvent(
            isPointerEventSupported
                ? new PointerEvent("list-item-icon-click", event)
                : new MouseEvent("list-item-icon-click", event)
        );
    }

    private getLevelIndicators(): number[] {
        if (!this.level) {
            return [];
        }
        // fill is needed because the ArrayConstructor
        // result an array with "empty" values in it
        // which are skipped when iterating
        return Array(Number(this.level)).fill(null);
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const generator = (data: ItemData, _index: number): ListItem => {
    const listItem = document.createElement(ListItem.ID) as ListItem;
    if (data) {
        listItem.caption = data.caption;
        listItem.description = data.description;
        listItem.icon = data.icon;
        listItem.iconBackgroundColor = data.iconBackgroundColor;
        listItem.iconPlaceholder = data.iconPlaceholder;
        listItem.level = data.level;
        if (data.contentMode) {
            listItem.contentMode = data.contentMode;
        }
        if (data.leftContentGenerator) {
            const leftContent = data.leftContentGenerator(data, listItem);
            if (leftContent) {
                leftContent.slot = "left-content";
                listItem.appendChild(leftContent);
            }
        }
        if (data.rightContentGenerator) {
            const rightContent = data.rightContentGenerator(data, listItem);
            if (rightContent) {
                rightContent.slot = "right-content";
                listItem.appendChild(rightContent);
            }
        }
    }
    return listItem;
};

ListItem.ensureDefined();
