(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lit"), require("lit/decorators/property.js"));
	else if(typeof define === 'function' && define.amd)
		define(["lit", "lit/decorators/property.js"], factory);
	else if(typeof exports === 'object')
		exports["@smartdesign/field-validation-message"] = factory(require("lit"), require("lit/decorators/property.js"));
	else
		root["@smartdesign/field-validation-message"] = factory(root["lit"], root["lit/decorators/property.js"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__870__, __WEBPACK_EXTERNAL_MODULE__961__) => {
return 