import { createPopper, Instance as Popper, Options, Placement } from "@popperjs/core";

const isIE11 = navigator.userAgent.indexOf("Trident") !== -1;
const arrowSvg = require(`./arrow.svg?raw`);
const arrowOverlapOffset = 1;
const targetClassOpen = "sd-popover-open";

export type TriggerType = "click" | "hover" | "manual";
export type ArrowVariant = "bottom" | "left" | "right" | "top";

export default class Popover extends HTMLElement {
    public static readonly ID: string = "sd-popover";
    private _open = false;
    private _popper: Popper;
    private _targetElement: HTMLElement;
    private _container: HTMLElement;
    private _arrow: HTMLElement;
    private _content: HTMLElement;
    private _firstUpdated = false;
    private _flipPriority: Placement[];

    public get triggerType(): TriggerType {
        return this.getAttribute("trigger-type") as TriggerType;
    }

    public set triggerType(type: TriggerType) {
        if (type) {
            this.setAttribute("trigger-type", type);
        } else {
            this.removeAttribute("trigger-type");
        }
    }

    public get placement(): Placement {
        return this.getAttribute("placement") as Placement;
    }

    public set placement(placement: Placement) {
        if (placement) {
            this.setAttribute("placement", placement);
        } else {
            this.removeAttribute("placement");
        }
    }

    public get targetElement(): HTMLElement {
        return this._targetElement;
    }

    public set targetElement(element: HTMLElement) {
        this.removeTargetEventListener(this.targetElement);
        this._targetElement = element;
        this.addTargetEventListener(this.targetElement);
        this.updatePopper();
    }

    public get targetSelector(): string {
        return this.getAttribute("target-selector");
    }

    public set targetSelector(selector: string) {
        if (selector) {
            this.setAttribute("target-selector", selector);
        } else {
            this.removeAttribute("target-selector");
        }
    }

    public get modal(): boolean {
        return this.hasAttribute("modal");
    }

    public set modal(modal: boolean) {
        if (modal) {
            this.setAttribute("modal", "");
        } else {
            this.removeAttribute("modal");
        }
    }

    public get popoverFor(): string {
        return this.getAttribute("popover-for");
    }

    public set popoverFor(popoverFor: string) {
        if (popoverFor) {
            this.setAttribute("popover-for", popoverFor);
        } else {
            this.removeAttribute("popover-for");
        }
    }

    public get flipPriority(): Placement[] {
        return this._flipPriority;
    }

    public set flipPriority(priority: Placement[]) {
        if (priority && priority.length > 0) {
            this._flipPriority = priority;
        } else {
            this._flipPriority = null;
        }
        this.reConfigurePopper();
    }

    public get offset(): number {
        return this.getAttribute("offset") ? parseInt(this.getAttribute("offset")) : 0;
    }

    public set offset(offset: number) {
        if (offset) {
            this.setAttribute("offset", offset.toString());
        } else {
            this.removeAttribute("offset");
        }
        this.reConfigurePopper();
    }

    private reConfigurePopper() {
        if (this._popper) {
            this.configurePopper();
        }
    }

    public get noArrow(): boolean {
        return this.hasAttribute("no-arrow");
    }

    public set noArrow(noArrow: boolean) {
        if (noArrow) {
            this.setAttribute("no-arrow", "");
        } else {
            this.removeAttribute("no-arrow");
        }
    }

    constructor() {
        super();
        this.style.display = "none";
    }

    static get observedAttributes(): string[] {
        return ["placement", "target-selector", "trigger-type"];
    }

    public attributeChangedCallback(name: string, oldValue: string): void {
        switch (name) {
            case "placement": {
                this.updatePopper();
                break;
            }
            case "trigger-type": {
                this.removeTargetEventListener(this.targetElement);
                this.addTargetEventListener(this.targetElement);
                break;
            }
            case "target-selector": {
                this.handleTargetSelectorChange(oldValue);
                break;
            }
        }
    }

    public connectedCallback(): void {
        if (!this._firstUpdated) {
            this.firstUpdated();
            this._firstUpdated = true;
        }
        if (this.targetElement) {
            this.addTargetEventListener(this.targetElement);
        }

        this._arrow = document.createElement("div");
        this._arrow.classList.add("popover-arrow");
        this._arrow.setAttribute("data-popper-arrow", "");
        this._arrow.style.display = "flex";
        this._arrow.innerHTML = arrowSvg;
    }

    public disconnectedCallback(): void {
        if (this.targetElement) {
            this.removeTargetEventListener(this.targetElement);
            this.removeTargetClassForOpen(this.targetElement);
        }
    }

    public toggle = (): void => {
        this.open = !this.open;
    };

    public show = (): void => {
        this.open = true;
    };

    public hide = (): void => {
        this.open = false;
    };

    private firstUpdated(): void {
        this.triggerType = this.triggerType || "click";
        this.placement = this.placement || "auto";
    }

    private get open(): boolean {
        return this._open;
    }

    private set open(open: boolean) {
        if (open !== this._open) {
            this._open = open;
            this.handleOpenChange();
        }
    }

    private handleOpenChange(): void {
        if (this.open) {
            if (!this._popper) {
                this.configurePopper();
            }

            this._container.innerHTML = "";
            if (this.popoverFor) {
                this._container.setAttribute("popover-for", this.popoverFor);
            }
            if (this.content) {
                this._container.appendChild(this.content);
                Object.assign(this.content.style, {
                    boxShadow: `0 4px 5px 0 rgba(0, 0, 0, 0.14),
                                0 1px 10px 0 rgba(0, 0, 0, 0.12),
                                0 2px 4px -1px rgba(0, 0, 0, 0.4)`,
                    display: "block",
                    backgroundColor: "#f2f2f2",
                    border: "1px solid #999",
                    boxSizing: "border-box",
                    overflow: "hidden",
                });
            }
            this.ensureArrowIsAdded();
            this.ownerDocument.body.appendChild(this._container);
            this.updatePopper();
            this.setAttribute("open", "");

            if (this.modal) {
                window.addEventListener("click", this.handleWindowPointerDown, { capture: true });
            }
            this.addTargetClassForOpen(this.targetElement);
            this.dispatchEvent(new CustomEvent("open"));
        } else {
            this.ownerDocument.body.removeChild(this._container);
            this.removeAttribute("open");
            window.removeEventListener("click", this.handleWindowPointerDown, { capture: true });
            this.removeTargetClassForOpen(this.targetElement);
            this.dispatchEvent(new CustomEvent("close"));
        }
    }

    private handleWindowPointerDown = (event: PointerEvent) => {
        if (
            !this.content.contains(event.target as HTMLElement) &&
            !this.targetElement.contains(event.target as HTMLElement)
        ) {
            this.hide();
        }
    };

    private handleTargetSelectorChange(oldValue: string): void {
        const oldTarget = document.querySelector<HTMLElement>(oldValue);
        if (oldTarget) {
            this.removeTargetEventListener(oldTarget);
            this.removeTargetClassForOpen(oldTarget);
        }
        if (this.targetSelector) {
            this.targetElement = document.querySelector(this.targetSelector);
            this.addTargetEventListener(this.targetElement);
        }
    }

    private addTargetEventListener(target: HTMLElement): void {
        if (target) {
            switch (this.triggerType) {
                case "hover": {
                    target.addEventListener("mouseenter", this.show);
                    target.addEventListener("mouseleave", this.hide);
                    break;
                }
                case "click": {
                    target.addEventListener("click", this.toggle);
                    break;
                }
            }
        }
    }

    private removeTargetEventListener(target: HTMLElement): void {
        if (target) {
            switch (this.triggerType) {
                case "hover": {
                    target.removeEventListener("mouseenter", this.show);
                    target.removeEventListener("mouseleave", this.hide);
                    break;
                }
                case "click": {
                    target.removeEventListener("click", this.toggle);
                    break;
                }
            }
        }
    }

    private configurePopper(): void {
        this.createContentContainer();
        // In popper.js 2 the arrow needs to be added before calling Popper#createPopper
        this.ensureArrowIsAdded();

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const popover = this;
        const options: Options = {
            placement: popover.placement as Placement,
            strategy: "absolute",
            modifiers: [
                {
                    name: "computeStyles",
                    options: {
                        gpuAcceleration: !isIE11,
                    },
                },
                {
                    name: "flip",
                    options: {
                        fallbackPlacements: popover._flipPriority,
                    },
                },
                {
                    name: "offset",
                    options: {
                        offset: () => {
                            return [
                                0,
                                popover.noArrow ? 0 + popover.offset : this._arrow.clientHeight + popover.offset,
                            ];
                        },
                    },
                },
                {
                    name: "arrow",
                    options: {
                        padding: 6, // Avoid to reach the edges of the popper element with the arrow
                    },
                },
                {
                    name: "resetArrowStyles",
                    enabled: true,
                    phase: "beforeWrite",
                    fn({ state }) {
                        Object.assign(popover._arrow.style, {
                            top: state.styles.arrow.top || "",
                            left: state.styles.arrow.left || "",
                            bottom: state.styles.arrow.bottom || "",
                            right: state.styles.arrow.right || "",
                        });
                    },
                },
                {
                    name: "onUpdate",
                    enabled: true,
                    phase: "afterWrite",
                    fn({ state }) {
                        popover.updateArrow(state.placement);
                    },
                },
            ],
        };
        this._popper = createPopper(this.targetElement, this._container, options);
    }

    private createContentContainer(): void {
        this._container = document.createElement("div");
        this._container.classList.add("popover-container");
    }

    private ensureArrowIsAdded() {
        if (!this.noArrow && !this._container.contains(this._arrow)) {
            this._container.appendChild(this._arrow);
        }
    }

    private updatePopper(): void {
        if (this._popper) {
            this._popper.state.elements.reference = this.targetElement;
            this._popper.state.options.placement = this.placement;

            if (this.open) {
                this._popper.update();
            }
        }
    }

    private updateArrow(placement: Placement): void {
        if (this._arrow) {
            const arrowVariant = this.getArrowVariant(placement);
            this._arrow.setAttribute("variant", arrowVariant);
            if (this._content) {
                const svg = this._arrow.querySelector("svg");

                const arrowWidth = this._arrow.clientWidth;
                const arrowHeight = this._arrow.clientHeight;
                const verticalOffset = `-${arrowHeight - arrowOverlapOffset}px`;

                const horizontalTransformationOffset = (arrowWidth - arrowHeight) / 2;
                // Note that arrowHeight is used on purpose for the horizontalOffset
                // as this is the height of the unrotated wrapper div which defines the gap between the
                // reference element and the popover.
                const horizontalOffset = `-${arrowHeight + horizontalTransformationOffset - arrowOverlapOffset}px`;

                switch (arrowVariant) {
                    case "bottom": {
                        this._arrow.style.top = verticalOffset;
                        svg.style.transform = "rotate(180deg)";
                        break;
                    }
                    case "top": {
                        this._arrow.style.bottom = verticalOffset;
                        svg.style.transform = "rotate(0deg)";
                        break;
                    }
                    case "left": {
                        this._arrow.style.right = horizontalOffset;
                        svg.style.transform = "rotate(270deg)";
                        break;
                    }
                    case "right": {
                        this._arrow.style.left = horizontalOffset;
                        svg.style.transform = "rotate(90deg)";
                        break;
                    }
                }
            }
        }
    }

    private getArrowVariant(placement: Placement): ArrowVariant {
        if (placement.indexOf("bottom") !== -1) {
            return "bottom";
        } else if (placement.indexOf("top") !== -1) {
            return "top";
        } else if (placement.indexOf("left") !== -1) {
            return "left";
        } else if (placement.indexOf("right") !== -1) {
            return "right";
        }
    }

    private get content(): HTMLElement {
        if (!this._content) {
            this._content = this.firstElementChild as HTMLElement;
            this._content.setAttribute("data-popper-content", "");
        }
        return this._content;
    }

    private addTargetClassForOpen(target: HTMLElement): void {
        if (target) {
            target.classList.add(targetClassOpen);
        }
    }

    private removeTargetClassForOpen(target: HTMLElement): void {
        if (target) {
            target.classList.remove(targetClassOpen);
        }
    }
}

if (!customElements.get(Popover.ID)) {
    customElements.define(Popover.ID, Popover);
}
