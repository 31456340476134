// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.85.1/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.85.1/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.85.1/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./resources/checkbox-edit.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./resources/checkbox-indeterminate.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host{display:block}:host,.label,.checkbox{outline:none}.checkbox-container{display:flex;flex-direction:row;align-items:flex-start}.checkbox{appearance:none;-moz-appearance:none;-webkit-appearance:none;display:block;margin:0;width:20px;height:20px;border:2px solid #767676;cursor:pointer;background-color:#fff}:host(:not([disabled]):focus) .highlight,:host(:not([disabled])[focused]) .highlight{background-color:rgba(20,103,186,.3);border-radius:4px}:host(:not([disabled]):focus) .highlight .checkbox,:host(:not([disabled])[focused]) .highlight .checkbox{border-color:#1467ba}:host([checked]) .checkbox{background:#1467ba url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;border-color:#1467ba}.checkbox.indeterminate{background:#1467ba url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center center;background-size:20px;border-color:#1467ba}.highlight{border:4px solid rgba(0,0,0,0);margin-right:6px}:host([disabled]) .checkbox-container{opacity:.6;cursor:default;filter:grayscale(100%);pointer-events:none}.validation-message{margin-left:4px}.label{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer;margin-top:4px;line-height:20px}:host([oneline]) .label{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
