import { LitElement, html, PropertyValues, unsafeCSS, css } from "lit";
import { property } from "lit/decorators/property.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { ValidationLevel } from "@smartdesign/field-validation-message";
const style = require("./style.scss");

const INDETERMINATE = "indeterminate";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const delegatesFocus = "delegatesFocus" in (window as any).ShadowRoot.prototype;

export class SDCheckbox extends LitElement {
    public static readonly ID: string = "sd-checkbox";
    public static ensureDefined = () => {
        if (!customElements.get(SDCheckbox.ID)) {
            customElements.define(SDCheckbox.ID, SDCheckbox);
        }
    };

    @property({ type: Boolean, reflect: true })
    public disabled: boolean;
    @property({ type: String, reflect: true })
    public label: string;
    @property({ type: String })
    public validationMessage: string;
    @property({ type: String })
    public validationIconSrc: string;
    @property({ type: ValidationLevel, reflect: true })
    public validationLevel: ValidationLevel;
    @property({ type: Number })
    public tabIndex: number;
    @property({ type: Boolean, reflect: true })
    public oneLine: boolean;

    private _checked = false;
    private _indeterminate = false;

    @property({ type: Boolean, reflect: true })
    public get checked(): boolean {
        return this._checked;
    }

    public set checked(value: boolean) {
        const oldValue = this._checked;
        this._checked = value;
        if (this._checked) {
            this.indeterminate = false;
        }
        this.requestUpdate("checked", oldValue);
    }

    @property({ type: Boolean, reflect: false, attribute: false })
    public get indeterminate(): boolean {
        return this._indeterminate;
    }

    public set indeterminate(value: boolean) {
        const oldValue = this._indeterminate;
        this._indeterminate = value;
        if (this._indeterminate) {
            this.checked = false;
        }
        this.requestUpdate("indeterminate", oldValue);
    }

    static shadowRootOptions: ShadowRootInit = {
        ...LitElement.shadowRootOptions,
        delegatesFocus,
    };

    protected firstUpdated(changedProperties: PropertyValues): void {
        super.firstUpdated(changedProperties);

        if (!delegatesFocus) {
            // Provide a limited "delegatesFocus" behavior where it is not supported.
            // Note that clicking on non-focusable elements inside the shadow root
            // does not focus the first focusable element as it would have been expected.
            this.checkboxElement.onfocus = () => this.setAttribute("focused", "");
            this.checkboxElement.onblur = () => this.removeAttribute("focused");
            this.addEventListener("focus", (event) => {
                if (event.target === this) {
                    this.checkboxElement.focus();
                }
            });
        }
    }

    public focus() {
        if (this.checkboxElement) {
            this.checkboxElement.focus();
        } else {
            super.focus();
        }
    }

    public attributeChangedCallback(name: string, old: string | null, value: string | null) {
        super.attributeChangedCallback(name, old, value);
        if (!delegatesFocus && "tabindex" === name && old !== value && value === "0") {
            // The externally given 0 tabindex causes issues in browsers where there is no delegatesFocus.
            // We can remove this attribute because this is the default value for such input elements.
            this.removeAttribute("tabindex");
        }
    }

    public render() {
        return html`
            <div class="checkbox-container">
                <div class="highlight">
                    <input
                        type="checkbox"
                        id="input"
                        ?disabled=${this.disabled}
                        tabindex=${ifDefined(this.tabIndex == null ? undefined : this.tabIndex)}
                        .checked=${this.checked}
                        .indeterminate=${this.indeterminate}
                        class="checkbox${this.indeterminate ? " " + INDETERMINATE : ""}"
                        @change="${this.onInputValueChange}"
                    />
                </div>
                <label class="label" for="input">${this.label}</label>
            </div>
            ${this.validationMessage &&
            html`
                <sd-field-validation-message
                    class="validation-message"
                    .message=${this.validationMessage}
                    .icon=${this.validationIconSrc}
                    .level=${this.validationLevel}
                >
                </sd-field-validation-message>
            `}
        `;
    }

    private onInputValueChange(event: UIEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.checked = (event.target as HTMLInputElement).checked;
        this.fireValueChange();
    }

    private fireValueChange(): void {
        this.dispatchEvent(new CustomEvent(`value-change`, { detail: { value: this.checked } }));
    }

    private get checkboxElement(): HTMLInputElement {
        return this.shadowRoot.querySelector<HTMLInputElement>(".checkbox");
    }

    static get styles() {
        return [
            css`
                ${unsafeCSS(style)}
            `,
        ];
    }
}

SDCheckbox.ensureDefined();
