export default class ImageTools {
    public static showImage(
        element: HTMLElement,
        src: string,
        placeholderSrc: string,
        useCssBackground?: boolean
    ): void {
        let setImage;
        if (useCssBackground || !(element instanceof HTMLImageElement)) {
            setImage = (image) => (element.style.backgroundImage = this.prepareSrc(image));
        } else {
            setImage = (image) => (element.src = image);
        }
        setImage(placeholderSrc);
        if (src) {
            const updateWithLoadedImage = () => {
                setImage(src);
            };
            const preloadingImage = new Image();
            preloadingImage.addEventListener("load", updateWithLoadedImage);
            preloadingImage.src = src;
            if (preloadingImage.complete) {
                preloadingImage.removeEventListener("load", updateWithLoadedImage);
                updateWithLoadedImage();
            }
        }
    }

    private static prepareSrc(src: string): string {
        if (src) {
            return src.startsWith("url") ? src : `url("${src}")`;
        }
        return null;
    }
}
