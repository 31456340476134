import hexRgb from "hex-rgb";

export interface IColor {
    red: number;
    green: number;
    blue: number;
    alpha: number;
}

export default class ColorBlender {
    private _rgbaColors: Map<number, IColor> = new Map();
    private _originalColors: Map<number, string> = new Map();

    public get colors(): Map<number, string> {
        return this._originalColors;
    }

    public set colors(newColors: Map<number, string>) {
        this._rgbaColors.clear();
        this._originalColors = newColors;
        if (newColors && newColors.size) {
            const invalidColorKey = Array.from(newColors.keys()).find((value) => {
                return value < 0 || value > 1;
            });
            if (invalidColorKey) {
                throw Error(
                    "The keys of the colors must represent the relative value where the color is fully applied."
                );
            }
            newColors.forEach((value, key) => {
                const color: IColor = this.convertFromRGBAString(value) || this.convertFromHex(value);
                if (Object.keys(color).length === 0 && color.constructor === Object) {
                    throw Error(`Cannot convert color: ${value} to rgba-color`);
                } else {
                    this._rgbaColors.set(key, color);
                }
            });
            const sortedEntries = Array.from(this._rgbaColors.entries()).sort();
            this._rgbaColors = new Map(sortedEntries);
        }
    }

    public blend(relativeValue: number): string {
        if (!this._rgbaColors.size) {
            throw Error("It is not possible to blend without a color list.");
        }
        let leftColor: [number, IColor];
        let rightColor: [number, IColor];
        for (const entry of this._rgbaColors) {
            if (!leftColor) {
                leftColor = entry;
            }
            if (entry[0] < relativeValue) {
                leftColor = entry;
            } else if (entry[0] === relativeValue) {
                leftColor = rightColor = entry;
                break;
            } else {
                rightColor = entry;
                break;
            }
        }
        if (!rightColor) {
            rightColor = leftColor;
        }
        const diff = rightColor[0] - leftColor[0];
        if (diff) {
            return this.blendColors(leftColor[1], rightColor[1], (relativeValue - leftColor[0]) / diff);
        } else {
            return this.convertToRGBAString(leftColor[1]);
        }
    }

    private blendColors(colorA: IColor, colorB: IColor, relativeOffsetFromA: number): string {
        return this.convertToRGBAString({
            red: Math.round(colorA.red + (colorB.red - colorA.red) * relativeOffsetFromA),
            green: Math.round(colorA.green + (colorB.green - colorA.green) * relativeOffsetFromA),
            blue: Math.round(colorA.blue + (colorB.blue - colorA.blue) * relativeOffsetFromA),
            alpha: colorA.alpha + (colorB.alpha - colorA.alpha) * relativeOffsetFromA,
        });
    }

    private convertFromHex(hexColor: string): IColor {
        const color = hexRgb(hexColor);
        if (color.alpha > 1) {
            color.alpha /= 255;
        }
        return color;
    }

    private convertFromRGBAString(rgbaColor: string): IColor {
        const match = rgbaColor.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
        return match
            ? {
                  red: parseInt(match[1], 10),
                  green: parseInt(match[2], 10),
                  blue: parseInt(match[3], 10),
                  alpha: parseInt(match[4], 10) || 1,
              }
            : null;
    }

    public transparentize(color: string, alpha: number): string {
        return this.convertToRGBAString(this.convertFromRGBAString(color), alpha);
    }

    private convertToRGBAString(color: IColor, alpha?: number): string {
        return `rgba(${color.red},${color.green},${color.blue},${alpha || color.alpha})`;
    }
}
