import { ElementBase } from "@smartdesign/element-base";

const htmlTemplate = require("!raw-loader!./button.html").default;

export class Button extends ElementBase {
    public static readonly ID: string = "sd-button";
    private _image: HTMLImageElement;
    private _isSpaceOrEnterKeyDown = false;

    public get imageElement() {
        return this._image;
    }

    public get icon(): string {
        return this.getAttribute("icon");
    }

    public set icon(icon: string) {
        if (this.icon !== icon) {
            if (icon) {
                this.setAttribute("icon", icon);
            } else {
                this.removeAttribute("icon");
            }
            this.updateIcon();
        }
    }

    public get primary(): boolean {
        return this.hasAttribute("primary");
    }

    public set primary(primary: boolean) {
        if (primary) {
            this.setAttribute("primary", "");
        } else {
            this.removeAttribute("primary");
        }
    }

    public get disabled(): boolean {
        return this.getAttribute("aria-disabled") == "true";
    }

    public set disabled(disabled: boolean) {
        this.setAttribute("aria-disabled", disabled.toString());
    }

    static get observedAttributes() {
        return ["icon"];
    }

    public is(): string {
        return Button.ID;
    }

    protected template(): HTMLTemplateElement {
        const template = document.createElement("template");
        template.innerHTML = htmlTemplate;
        return template;
    }

    constructor() {
        super();
        this.addEventListener("keydown", (e) => {
            if (this.isSpaceOrEnter(e)) {
                this._isSpaceOrEnterKeyDown = true;
                e.preventDefault(); // prevent potential scroll-down triggered by space
            }
        });
        this.addEventListener("keyup", (e) => {
            if (this.isSpaceOrEnter(e) && this._isSpaceOrEnterKeyDown == true) {
                this._isSpaceOrEnterKeyDown = false;
                this.click();
            }
        });
    }

    private isSpaceOrEnter(event: KeyboardEvent): boolean {
        switch (event.key) {
            case " ":
            case "Space":
            case "Enter":
                return true;
            default:
                return false;
        }
    }

    public click() {
        if (!this.disabled && !this.hasAttribute("disabled")) {
            super.click();
        }
    }

    public connectedCallback() {
        super.connectedCallback();

        if (!this.hasAttribute("tabIndex")) {
            this.tabIndex = 0;
        }

        this._image = this.shadowRoot.querySelector(".icon") as HTMLImageElement;

        this.updateIcon();
        if (!this.hasAttribute("role")) {
            this.setAttribute("role", "button");
        }
    }

    public attributeChangedCallback(): void {
        this.updateIcon();
    }

    private updateIcon() {
        if (this._image) {
            const iconSrc = this.getAttribute("icon");
            if (iconSrc) {
                this._image.src = iconSrc;
            }
        }
    }
}

if (!customElements.get(Button.ID)) {
    customElements.define(Button.ID, Button);
}
