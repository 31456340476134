(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@smartdesign/field-validation-message"), require("lit"), require("lit/decorators/property.js"), require("lit/directives/if-defined.js"));
	else if(typeof define === 'function' && define.amd)
		define(["@smartdesign/field-validation-message", "lit", "lit/decorators/property.js", "lit/directives/if-defined.js"], factory);
	else if(typeof exports === 'object')
		exports["@smartdesign/checkbox"] = factory(require("@smartdesign/field-validation-message"), require("lit"), require("lit/decorators/property.js"), require("lit/directives/if-defined.js"));
	else
		root["@smartdesign/checkbox"] = factory(root["@smartdesign/field-validation-message"], root["lit"], root["lit/decorators/property.js"], root["lit/directives/if-defined.js"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__511__, __WEBPACK_EXTERNAL_MODULE__870__, __WEBPACK_EXTERNAL_MODULE__961__, __WEBPACK_EXTERNAL_MODULE__949__) => {
return 